<div class="StockOptions-total" *ngIf="isVehicleFound" data-cy="truck-count">
  <b>{{ finalCount }}</b> {{ 'globalMiss/vehiclesFound' | lowercase | translate }}
</div>

<mat-form-field [matMenuTriggerFor]="sort" *ngIf="!isVehicleFound" [class.isChanged]="isChanged" floatLabel="never"
  appearance="outline" class="MatInput StockOptions-input">
  <mat-select *ngIf="SortOptions.oldestAge === search.sort"
    placeholder="{{ 'globalMiss/sortNewInStock' | lowercase | translate }}">
  </mat-select>
  <mat-select *ngIf="SortOptions.lowestPrice === search.sort"
    placeholder="{{ 'globalMiss/sortLowToHigh' | lowercase | translate }}">
  </mat-select>
  <mat-select *ngIf="SortOptions.highestPrice === search.sort"
    placeholder="{{ 'globalMiss/sortHighToLow' | lowercase | translate }}"></mat-select>
</mat-form-field>

<mat-menu #sort="matMenu" class="AutoMenu">
  <div class="StockOptions-menu">
    <button (click)="onSortChange(SortOptions.oldestAge)" [class.Active]="SortOptions.oldestAge === search.sort"
      mat-button>
      {{ 'globalMiss/newInStock' | lowercase | translate | capitalize }}
    </button>
    <button (click)="onSortChange(SortOptions.lowestPrice)" [class.Active]="SortOptions.lowestPrice === search.sort"
      mat-button>
      {{ 'globalMiss/price' | lowercase | translate }} {{ 'globalMiss/lowToHigh' | lowercase | translate }}
    </button>
    <button (click)="onSortChange(SortOptions.highestPrice)" [class.Active]="SortOptions.highestPrice === search.sort"
      mat-button>
      {{ 'globalMiss/price' | lowercase | translate }} {{ 'globalMiss/highToLow' | lowercase | translate }}
    </button>
  </div>
</mat-menu>
