<div class="tabMobSidePadding">
    <div class="truck">
        <volvo-truck-model-skeleton *ngIf="isLoading"></volvo-truck-model-skeleton>
        <div [hidden]="isLoading">
            <section class="sectionA">
                <div class="tabMobHeroMargin">
                    <img [src]="imagePath" (load)="onImageLoad()" class="sectionA-truckImg" alt="{{imageAltText| lowercase | translate}}">
                </div>
                <div class="sectionA-imgTitle">
                    {{modelTitle | lowercase | translate}}
                </div>
                <div class="sectionA-title">
                    {{modelName| lowercase | translate}}
                </div>
                <div class="sectionA-text" [innerHTML]="modelText | lowercase | translate">
                </div>
                <div>
                    <a href="javascript:void(0);" (click)="TruckModelService.searchTruck('Volvo',model)" 
                    role="button" tabindex="0">
                        {{'pages/truckModel/stock' | lowercase | translate}}
                        <i class="fal fa-chevron-right"></i>
                    </a>
                </div>
            </section>
            <section class="sectionB">
                <div class="sectionB-title">
                    {{modelName| lowercase | translate}} (2013-2021) {{
'pages/truckModel/classic/highlights'
            | lowercase | translate
                    }}
                </div>
                <div class="sectionB-items">
                    <div class="sectionB-dragScrollItems">
                        <drag-scroll #slider class="drag-scroll-style" (reachesLeftBound)="leftNavDisabled=TruckModelService.leftBoundStat($event)"
                                     (reachesRightBound)="rightNavDisabled=TruckModelService.rightBoundStat($event)">
                            <div drag-scroll-item *ngFor="let list of modelObj;let last = last;" class="sectionB-dragScroll">
                                <div class="sectionB-item" [ngClass]="last?'sectionB-margin-right':''">
                                    <div>
                                        <img [src]="assetService.getAssetUrl(list.url)" class="sectionB-itemImg" alt="{{ list.altImg | lowercase | translate }}"/>
                                    </div>
                                    <div class="sectionB-itemTitle">
                                        {{ list.title | lowercase | translate }}
                                    </div>
                                    <div class="sectionB-itemText" [innerHTML]="list.text | lowercase | translate"></div>
                                </div>
                            </div>
                        </drag-scroll>
                        <div>
                            <button (click)="slider.moveLeft()" [disabled]="leftNavDisabled" class="sectionB-moveLeft" aria-label="Move left">
                                <i class="fal fa-chevron-left"></i>
                                <span class="sr-only">Move left</span>
                            </button>
                            <button (click)="slider.moveRight()" [disabled]="rightNavDisabled" class="sectionB-moveRight" aria-label="Move right">
                                <i class="fal fa-chevron-right"></i>
                                <span class="sr-only">Move right</span>
                            </button>
                        </div>
                    </div>

                </div>
                <div class="sectionB-design">
                    <div *ngFor="let list of designObj" class="sectionB-design-div">
                        <img [src]="assetService.getAssetUrl(list.url)" alt="{{ list.altImg | lowercase | translate }}">
                        <div class="sectionB-design-item">
                            <div class="sectionB-design-item-title">
                                {{ list.title | lowercase | translate }}
                            </div>
                            <div class="sectionB-design-item-text">
                                {{list.text | lowercase| translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sectionB-title">
                    {{modelName| lowercase | translate}} (2013-2021) {{
'pages/truckModel/caboptions'
            | lowercase | translate
                    }}
                </div>

                <div class="sectionB-items">
                    <div class="sectionB-dragScrollItems">
                        <drag-scroll #sliderCab class="drag-scroll-style" (reachesLeftBound)="leftCabNavDisabled=TruckModelService.leftBoundStat($event)"
                                     (reachesRightBound)="rightCabNavDisabled=TruckModelService.rightBoundStat($event)">
                            <div drag-scroll-item *ngFor="let list of cabObj;let last = last;" class="sectionB-dragScroll">
                                <div class="sectionB-item" [ngClass]="last?'sectionB-margin-right':''">
                                    <div>
                                        <img [src]="assetService.getAssetUrl(list.url)" class="sectionB-itemImg" alt="{{ list.altImg | lowercase | translate }}"/>
                                    </div>
                                    <div class="sectionB-itemTitle">
                                        {{ list.title | lowercase | translate }}
                                    </div>
                                    <div class="sectionB-itemText" [innerHTML]="list.text | lowercase | translate"></div>
                                </div>
                            </div>
                        </drag-scroll>
                        <div>
                            <button (click)="sliderCab.moveLeft()" [disabled]="leftCabNavDisabled" class="sectionB-moveLeft" aria-label="Move left">
                                <i class="fal fa-chevron-left"></i>
                                <span class="sr-only">Move left</span>
                            </button>
                            <button (click)="sliderCab.moveRight()" [disabled]="rightCabNavDisabled" class="sectionB-moveRight" aria-label="Move right">
                                <i class="fal fa-chevron-right"></i>
                                <span class="sr-only">Move right</span>
                            </button>
                        </div>
                    </div>

                </div>
            </section>
            <section class="sectionC isDesktop">
                <div *ngFor="let list of engineObj;let j=index;">
                    <div class="sectionC-title">
                        {{list.name | lowercase |translate}}
                    </div>
                    <div [ngClass]="j===0?'sectionC-height':''">
                        <div class="sectionC-engine">
                            <div *ngFor="let category of list.categories">
                                <div class="sectionC-engine-title">
                                    {{category.title | lowercase | translate}}
                                </div>
                                <div *ngFor="let column of category.colums;let i=index;" class="sectionC-engine-columns" [ngClass]="(i%2)===0?'sectionC-engine-columns-even':'sectionC-engine-columns-odd'">
                                    <div>
                                        {{column.column1 | lowercase | translate}}
                                    </div>
                                    <div>
                                        {{column.column2}}{{column.column3| lowercase |translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sectionC-engine-text">
                        <span *ngIf="engineObj.length>1 && j!==0">{{list.text| lowercase |translate}}</span>
                        <span *ngIf="engineObj.length===1 && j===0">{{list.text| lowercase |translate}}</span>
                    </div>
                </div>
            </section>
            <div class="isMobile">
                <div class="sectionC-title">
                    {{'pages/truckModel/engines' | lowercase| translate}} {{modelName| lowercase | translate}}
                </div>
                <div class="sectionC-engine-anchor">
                    <a (click)="TruckModelService.openDialog('engine',engineObj[0])">
                        {{'global/view'|lowercase| translate}} {{'pages/truckModel/engines' | lowercase| translate}}
                        <i class="fal fa-chevron-right"></i>
                    </a>
                </div>
                <div class="sectionC-engine-anchor" *ngIf="engineObj.length>1">
                    <a (click)="TruckModelService.openDialog('lng',engineObj[1])">
                        {{'global/view'|lowercase| translate}} {{'pages/truckModel/lngengines' | lowercase| translate}}
                        <i class="fal fa-chevron-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
    <volvo-footer></volvo-footer>

