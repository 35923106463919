import { AfterViewInit, Component, Input, ViewEncapsulation, Renderer2, ElementRef, OnInit, HostListener } from '@angular/core';
import { LightGallery } from 'lightgallery/lightgallery';
import { VolvoCdnPipe } from 'src/app/core/pipes/volvo-cdn.pipe';
import { Picture, Video } from 'src/app/core/store/trucks/trucks.model.base';
import { GlobalUtils } from 'src/app/core/utils/global-utils/global-utils';
import { GalleryItem } from 'lightgallery/lg-utils';

import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';
import { InitialState } from '@ngxs/store/internals';
import { videoSettings } from 'lightgallery/plugins/video/lg-video-settings';
import { ScreenUtils } from '../../../core/utils/screen-utils/screen-utils';
@Component({
  selector: 'volvo-thumb-slider',
  templateUrl: './thumb-slider.component.html',
  styleUrls: ['./thumb-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThumbSliderComponent implements AfterViewInit {
  @Input() offer: string = ""
  isBrowser: boolean;
  isPhone: boolean = false;
  public lightGallery!: LightGallery;
  galleryImages: any[] = [];
  @Input() truckStatus: boolean;
  screenWidth: number;
  @Input() set slides(slides: Picture[]) {
    this.galleryImages = slides.map((s: GalleryItem, i: number) => {
      return {
        thumb: this.volvoCdnPipe.transform(s.filenameSmall),
        src: this.volvoCdnPipe.transform(s.filenameLarge),
        //subHtml: "<div class=\"caption\"><b>Potential title for image #" + i + "</b></div><br><div class=\"caption\">Potential description for image #" + i + "</div>"
      }
    });
  }
  @Input() set videos(videos: Video[]) {
    if (videos) {
      videos.forEach(video => {
        if (video.videoLink == null || video.videoLink == "") {
          return;
        }
        const videoId = this.extractVideoId(video.videoLink);
        const videoLink = videoId ? `https://www.youtube.com/embed/${videoId}` : video.videoLink;

        this.galleryImages.push({
          src: videoLink,
          iframe: true
        });
      });
    }
    if(this.offer === "Selected" || this.offer === "Approved"){
      this.galleryImages.push({
        src:"https://www.youtube.com/embed/a7Ko5hrsWo0",
        iframe: true
      });
    } else if(this.offer === "Economy"){
      this.galleryImages.push({
        src:"https://www.youtube.com/embed/d8zEJ9bEjMc",
        iframe: true
      });
    }
  }

  private extractVideoId(videoLink: string): string {
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^?&]+)/;
    const match = videoLink.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  }


  constructor(
    private globalUtils: GlobalUtils,
    private volvoCdnPipe: VolvoCdnPipe,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.isBrowser = this.globalUtils.isBrowser();
    this.isPhone = ScreenUtils.isPhone() || ScreenUtils.isTablet();
  }

  ngAfterViewInit(): void {
    const inlineGallery = lightGallery(document.getElementById("inline-gallery-container"), {
      licenseKey: "2199D8AD-8EEE4A3F-B9E911E7-3DD5070F",
      container: document.getElementById("inline-gallery-container"),
      hash: false,
      closable: false,
      showMaximizeIcon: true,
      alignThumbnails: 'middle',
      slideDelay: 50,
      plugins: [lgThumbnail, lgZoom, lgVideo],
      dynamicEl: this.galleryImages,
      thumbMargin: 2,
      counter: true,
      download: false,
      zoom: false,
      videoMaxSize: "1280-720",
      allowMediaOverlap: false,
      animateThumb: true,
      appendThumbnailsTo: '.lg-components',
      preload: 3,
      progressBar: true,
      toggleThumb: false,
      thumbWidth: this.isPhone ? 100 : 200,
      thumbHeight: this.isPhone ? '80px' : '150px',
      height: '100%'
    });

    this.lightGallery = inlineGallery;
    this.lightGallery.refresh(this.galleryImages);
    this.lightGallery.openGallery();
    //this.onImageLoad();
    //this.adjustContainerHeight();
  }

  private fixVideoContainerSize(): void {
    const videoContainers = document.querySelectorAll('.lg-video-cont');
    videoContainers.forEach((container: HTMLElement) => {
      container.style.height = '100%';
      container.style.width = '100%';
    });
  }
  checkImageLoaded(event: any) {
    const currentSlideIndex = event.detail.index;
    const currentSlide = event.target;
    const currentImage = currentSlide.querySelector('img');
    currentImage.onerror = () => {
      this.galleryImages[0].thumb = "assets/icons/Imagescomingsoon.png",
        this.galleryImages[0].src = "assets/icons/Imagescomingsoon.png",
        this.lightGallery.updateSlides(this.galleryImages, 0);
      this.lightGallery.refresh();
    }
  }
  ngOnInit() {
    this.getScreenSize();
  }

  ngDoCheck() {
    // Set the initial container height
    this.adjustContainerHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenSize();
  }

  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('click', ['$event'])
  adjustContainerHeight() {
    this.fixVideoContainerSize();
    const container = this.elementRef.nativeElement.querySelector('#inline-gallery-container');
    const soldBanner = this.elementRef.nativeElement.querySelector('div.SoldBannerImage.ng-star-inserted');
    const image = this.elementRef.nativeElement.querySelector('img.lg-object.lg-image');
    this.isPhone = ScreenUtils.isPhone() || ScreenUtils.isTablet();

    if (container && image) {

      const renderHeight = container.offsetWidth / image.width * image.height + 175;
      this.renderer.setStyle(container, 'height', renderHeight + 'px');
      if (this.isPhone) {
        const renderHeight = container.offsetWidth / image.width * image.height + 100;
        this.renderer.setStyle(container, 'height', renderHeight + 'px');
      }
    }

    if (soldBanner) {
      if(this.truckStatus){
        const renderHeight = container.offsetWidth / image.width * image.height + 165;
        this.renderer.setStyle(soldBanner, 'height', renderHeight + 'px');
        this.renderer.setStyle(soldBanner, 'width', image.width + 'px');

        if (this.isPhone) {
          const renderHeight = container.offsetWidth / image.width * image.height + 95;
          this.renderer.setStyle(soldBanner, 'height', renderHeight + 'px');
          this.renderer.setStyle(soldBanner, 'width', image.width + 'px');
        }
      }
    }
  }
}
