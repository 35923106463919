import { NavigationStart, Router, RoutesRecognized, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { CanonicalService } from '~/core/services/global/canonical.service';

import { CookiesPopupComponent, CookiesStatus } from '~/popups/cookies-popup/cookies-popup.component';
import { NavLinks, Sidenavs, SidenavService } from '~/core/services/sidenav/sidenav.service';
import { BucketAggregations } from '~/core/store/buckets/buckets.model';
import { ContactPopupComponent } from '~/popups/contact-popup/contact-popup.component';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { BucketsService } from '~/core/store/buckets/buckets.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalService } from '~/core/services/global/global.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { TrucksService } from '~/core/store/trucks/trucks.service';
import { SearchService } from '~/core/store/search/search.service';
import { Map } from '~/core/store/dealerships/dealerships.model';
import { Truck } from '~/core/store/trucks/trucks.model';
import { RoutesPageData } from '~/app-routing.module';
import { HtmlRoutePages } from '~/app-routing.model';
import { routerTransition } from '~/animations';
import { LocalesStateLangList, LocalesStateRegion, LocalesStateRegionList, LocalesStateCountryList, LocalesStateCountry, LocalesStateInterface } from './core/store/locales/locales.model';
import { Subscription, Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SignalrService } from './core/services/signalr/signalr-service';
import { TranslationService } from './core/services/translations/translation-service';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { PrintService } from './core/services/print/print.service';
import { CounterService } from './core/services/counter/counter-service';
import { VIEWER, langList } from './core/constants/constants';
import { Store } from '@ngxs/store';

declare let gtag: Function;

import { regionEnum } from './core/declaration/declaration';
import { OnlineStatusService } from './core/services/onlineStatus/onlineStatus.service';
import { RegionalSettingsService } from './core/store/regionalSettings/regionalSettings.service';
import { ToggleFeatureService } from './core/services/togglefeature/togglefeature.service';
import { ScrollService } from './core/services/scroller/scroll.service';
import { CommonBreadcrumbService } from './core/services/breadcrumb/commonBreadcrumb.service';
import { MapsService } from './core/services/maps/maps.service';

import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}

interface Language {
  label: string;
  id: string;
  apiResLang: string;
}

interface LanguageObject {
  curLang: Language;
  list: Language[];
}

@Component({
  selector: 'volvo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menu', { static: true }) menu: MatSidenav;
  @ViewChild('product', { static: true }) product: MatSidenav;
  @ViewChild('mapContainer') gmap: ElementRef;

  isActive: boolean = false;
  map: Map;
  navLinks: NavLinks[];
  activeTruck: Truck;
  isSideNavOpen: boolean;
  cookie: any;
  private s0: Subscription;
  lang: string;
  langCode: string;
  regionId: string; //= "EU";
  countryId: string;
  HtmlRoutePages = HtmlRoutePages;
  loading = true;
  updateLang: { label: string, id: string, apiResLang: string };
  updateRegion: { label: string, id: string };
  updateCountry: { label: string, id: string };
  cookieStatus: any;
  routeurl: any;
  sub: any;
  userstatus_lang: string;
  ticks = 0;


  loginDisplay = false;
  tokenExpiration: string = '';
  private readonly _destroying$ = new Subject<void>();

  langRegion: any;
  langCountry: any;
  showBreadCrumbs: boolean = false;

  langObj = {
    curLang: { label: 'English', id: 'en', apiResLang: 'en', code: 'en-US' },
    list: [
      { label: 'English', id: 'en', apiResLang: 'en', code: 'en-US' },
      { label: 'Lietuvių', id: 'lt', apiResLang: 'lt', code: 'lt-LT' },
      { label: 'Čeština', id: 'cz', apiResLang: 'cs', code: 'cs-CZ' },
      { label: 'Dansk', id: 'dk', apiResLang: 'da', code: 'da-DK' },
      { label: 'Français', id: 'fr', apiResLang: 'fr', code: 'fr-FR' },
      { label: 'Español', id: 'es', apiResLang: 'es', code: 'es-ES' },
      { label: 'Norsk', id: 'no', apiResLang: 'no', code: 'no-NO' },
      { label: 'Italiano', id: 'it', apiResLang: 'it', code: 'it-IT' },
      { label: 'Suomi', id: 'fi', apiResLang: 'fi', code: 'fi-FI' },
      { label: 'Polski', id: 'pl', apiResLang: 'pl', code: 'pl-PL' },
      { label: 'Magyar', id: 'hu', apiResLang: 'hu', code: 'hu-HU' },
      { label: 'Slovenĝina', id: 'sk', apiResLang: 'sk', code: 'sk-SK' },
      { label: 'Slovenšĝina', id: 'si', apiResLang: 'si', code: 'sl-SI' },
      { label: 'Deutsch (AT)', id: 'at', apiResLang: 'de-at', code: 'de-AT' },
      { label: 'Română', id: 'ro', apiResLang: 'ro', code: 'ro-RO' },
      { label: 'Bosanski', id: 'ba', apiResLang: 'bs', code: 'bs-BA' },
      { label: 'Македонѝки', id: 'mk', apiResLang: 'mk', code: 'mk-MK' },
      { label: 'Српѝки', id: 'rs', apiResLang: 'rs', code: 'sr-RS' },
      { label: 'Hrvatski', id: 'hr', apiResLang: 'hr', code: 'hr-HR' },
      { label: 'Deutsch (DE)', id: 'de', apiResLang: 'de', code: 'de-DE' },
      { label: 'Nederlands', id: 'nl', apiResLang: 'nl', code: 'nl-NL' },
      { label: 'Българѝки', id: 'bg', apiResLang: 'bg', code: 'bg-BG' },
      { label: 'Svenska', id: 'se', apiResLang: 'sv', code: 'sv-SE' },
      { label: 'Eesti', id: 'ee', apiResLang: 'et', code: 'et-EE' },
      { label: 'Latviešu', id: 'lv', apiResLang: 'lv', code: 'lv-LV' },
      { label: 'Руѝѝкий', id: 'ru', apiResLang: 'ru', code: 'ru-RU' },
      { label: 'Français (BE)', id: 'be-fr', apiResLang: 'fr', code: 'fr-BE' },
      { label: 'Nederlands (BE)', id: 'be-nl', apiResLang: 'nl', code: 'nl-BE' },
      { label: 'Deutsch (CH)', id: 'ch-de', apiResLang: 'de', code: 'de-CH' },
      { label: 'Français (CH)', id: 'ch-fr', apiResLang: 'fr', code: 'fr-CH' },
      { label: 'Italiano (CH)', id: 'ch-it', apiResLang: 'it', code: 'it-CH' },
      { label: 'English', id: 'en-za', apiResLang: 'en-za', code: 'en-ZA' },
      { label: 'English', id: 'en-gb', apiResLang: 'en-gb', code: 'en-GB' },
      { label: 'English', id: 'en-tx', apiResLang: 'en-tx', code: 'en-TX' },
    ]
  };
  region: string;
  regionEnum = regionEnum;
  isSelectedSite: boolean = false;

  constructor(
    public dialog: MatDialog,
    public sidenavService: SidenavService,
    public searchService: SearchService,
    public router: Router,
    private trucksService: TrucksService,
    private bucketsService: BucketsService,
    private titleService: Title,
    private dealershipsService: DealershipsService,
    private globalService: GlobalService,
    private localesService: LocalesService,
    private globalUtils: GlobalUtils,
    private location: Location,
    private canonicalService: CanonicalService,
    private metaTagService: Meta,
    private http: HttpClient,
    private signalRService: SignalrService,
    private translateService: TranslateService,
    private translationService: TranslationService,
    public printService: PrintService,
    public counterService: CounterService,
    public store: Store,
    private scrollService: ScrollService,
    private toggleFeatureService: ToggleFeatureService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private locale: string,
    private renderer: Renderer2,
    @Optional() @Inject(VIEWER) private viewer,
    public onlineStatus: OnlineStatusService,
    public regionSettingService: RegionalSettingsService,
    public mapsService: MapsService,
    private breadcrumbService: CommonBreadcrumbService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
    registerLocaleData(localeDe, 'de');

    this.navLinks = this.sidenavService.getNavLinks();
    this.navigationStartSubscription();
    this.navigationEndSubscription();
    this.globalEvents();
  }

  ngOnInit(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      })

    // Used for storing and displaying token expiration
    this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)).subscribe(msg => {
      this.tokenExpiration = (msg.payload as any).expiresOn;
      localStorage.setItem('tokenExpiration', this.tokenExpiration);
    });

    this.initHostSettings();
    this.globalService.editCookies.subscribe(() => {
      this.openCookiesPopup();
    });
    this.filterNavigationLinks();
    this.navigationStartSubscription();
    this.navigationEndSubscription();
    this.sidenavService.setSidenav(this.menu, Sidenavs.menu);
    this.sidenavService.setSidenav(this.product, Sidenavs.product);
    this.handleJaegerDebugId();
    this.handleCookieConsent();
    this.sub = this.router.events.subscribe(val => {
      if (val instanceof RoutesRecognized) {
        this.routeurl = val.state.url;
      }
    });
    this.scrollService.listenToRouterEvents();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private async initHostSettings() {
    var langObj = JSON.parse(localStorage.getItem('language'));
    this.region = langObj.region.curRegion.id;
    this.regionSettingService.setRegion(this.region);
    this.localesService.setLocales(langObj);
    this.localesService.initLanguage();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  // Log the user in and redirect them if MSAL provides a redirect URI otherwise go to the default URI
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  // Log the user out
  logout() {
    this.authService.logoutRedirect();
  }

  private filterNavigationLinks(): void {
    var filteredAry = this.sidenavService.getNavLinks().filter(e => (e.children === undefined || e.children.length <= 8) && (e.path != HtmlRoutePages.buyersGuide));
    this.navLinks = filteredAry;
    if (this.region === regionEnum.TX) {
      this.navLinks = this.navLinks.filter(x => x.path != HtmlRoutePages.vehicleOffers);
    }
  }

  private handleJaegerDebugId(): void {
    let correlationId = localStorage.getItem('jaeger-debug-id');
    const correlationIdAge = Number.parseInt(localStorage.getItem('jaeger-debug-id-time'));
    let date = new Date();

    if (!correlationIdAge || !correlationId || (date.getUTCDate() - correlationIdAge > 0)) {
      localStorage.removeItem('jaeger-debug-id');
      localStorage.removeItem('jaeger-debug-id-time');
    }

    if (localStorage.getItem('jaeger-debug-id') === null) {
      this.counterService.postSession();
    }
  }

  private handleCookieConsent(): void {
    this.cookie = this.getCookie('cookieconsent_status');
    try {
      this.cookieStatus = JSON.parse(this.cookie);
    } catch {
      this.openCookiesPopup();
    }
  }

  private initSubscriptions(): void {
    this.dealershipsService.getDealerships();

    this.trucksService.activeTruck$.subscribe((activeTruck: Truck) => {
      this.activeTruck = activeTruck;
    });

    this.onlineStatus.online$.subscribe(result => {

    });

    this.onlineStatus.offline$.subscribe(result => {

    });

    this.bucketsService.buckets$.subscribe((aggregations: BucketAggregations) => {
      if (aggregations && aggregations.stockLocationCountry) {
        this.dealershipsService.getDealerships();
      }
    });

    this.localesService.country$.subscribe((country: LocalesStateCountry) => {
      if (country) {
        if (country.curCountry) {
          this.countryId = country.curCountry.id;
        } else this.countryId = null;
      }
    });
    this.localesService.region$.subscribe((region: LocalesStateRegion) => {
      if (region) {
        if (region.curRegion) {
          this.regionId = region.curRegion.id;
        } else this.regionId = "EU";
      }
    });
  }



  truckPath() {
    return this.activeTruck.stockNumber + "/volvoselected/" + this.regionId;
  }

  ngAfterViewInit(): void {

  }

  onMailClick(): void {
    this.dialog.open(ContactPopupComponent, {
      panelClass: 'volvo-popup'
    });
  }

  onTruckSidenavToggle(isSideNavOpen: boolean): void {
    this.isSideNavOpen = isSideNavOpen;

    if (!isSideNavOpen) {
      this.trucksService.setActiveTruck(null);
    }
  }

  private getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  private initCookies(): void {
    if (this.globalUtils.isBrowser()) {
      if (document.cookie.indexOf('cookieconsent_status=') != -1) {
        if (
          this.getCookie('cookieconsent_status') === 'allow' ||
          this.getCookie('cookieconsent_status') === 'dismiss'
        ) {
          this.openCookiesPopup();
          location.reload();
        }
        return;
      }

      this.openCookiesPopup();
    }
  }

  private openCookiesPopup(): void {
    // if (this.globalUtils.isBrowser()) {
    //   const dialogRef = this.dialog.open(CookiesPopupComponent, {
    //     panelClass: 'volvo-popup',
    //     closeOnNavigation: true,
    //     disableClose: true
    //   });

    //   dialogRef.afterClosed().subscribe((data: any) => {
    //     if (data.status === CookiesStatus.allow || data.status === CookiesStatus.dismiss) {
    //       // tslint:disable-next-line:new-parens
    //       const cookieDate = new Date();
    //       cookieDate.setFullYear(cookieDate.getFullYear() + 1);

    //       const stringifyCookie = JSON.stringify(data);
    //       document.cookie = `cookieconsent_status=${stringifyCookie}; expires=${cookieDate.toUTCString()}`;

    //       sessionStorage.removeItem('language');
    //       localStorage.removeItem('language');
    localStorage.setItem('persist', 'true');

    //       location.reload();
    //     }

    //     if (status === CookiesStatus.allow) {
    //       // this.installAnalytics();
    //     }
    //   });
    // }
  }



  private fixContentWhenPopupIsOpened(): void {
    if (this.globalUtils.isBrowser()) {
      const addVolvoPopup = () => {
        document.body.classList.add('VolvoPopupIsOpened');
      };
      const removeVolvoPopup = () => {
        document.body.classList.remove('VolvoPopupIsOpened');
      };

      this.menu.openedStart.subscribe(addVolvoPopup);
      this.menu.closedStart.subscribe(removeVolvoPopup);
      this.product.openedStart.subscribe(addVolvoPopup);
      this.product.closedStart.subscribe(removeVolvoPopup);
      this.dialog.afterOpened.subscribe(addVolvoPopup);
      this.dialog.afterAllClosed.subscribe(removeVolvoPopup);
    }
  }

  private navigationStartSubscription(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event: NavigationStart) => {
        this.sidenavService.closeAllSidenavs();

        if (event.url === HtmlRoutePages.privacyPolicy) {
          this.dialog.closeAll();
        } else {
          this.initCookies();
        }
      });
  }

  private navigationEndSubscription(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof RoutesRecognized),
        map((event: RoutesRecognized) => event.state.root.firstChild.data)
      )
      .subscribe((page: RoutesPageData) => {
        //not required here, as we alreay have page level title update feature.
        this.titleService.setTitle(page.title);
      });
  }

  private globalEvents(): void {
    if (this.globalUtils.isBrowser()) {
      const checkWord = (text: string, word: string) => {
        return text.slice(-word.length) === word;
      };

      const testError = () => {
        let text = '';
        let isActivated = false;
        const activationChar = 'KeyE';
        const error = 'error';
        document.addEventListener(
          'keypress',
          e => {
            if (e.ctrlKey && e.shiftKey && e.code === activationChar) {
              isActivated = true;
            }

            if (isActivated) {
              text += e.key;
            }

            if (checkWord(text, error)) {
              isActivated = false;
              text = '';
              throw new Error('Test Error');
            }
          },
          false
        );
      };

      testError();
    }
  }
  ActivateRoute(navlink): void {
    navlink.isActive = !navlink.isActive;
  }
  showorHideBreadCrumbs(value) {
    this.showBreadCrumbs = value;
  }
  logTruckModelButtonClick(path: string, label: string) {
    switch (path) {
      case this.HtmlRoutePages.truckModels: this.counterService.postTruckModelClick('Truck Model Home'); break;
      case this.HtmlRoutePages.volvoFH: this.counterService.postTruckModelClick('Volvo FH'); break;
      case this.HtmlRoutePages.volvoFH16: this.counterService.postTruckModelClick('Volvo FH16'); break;
      case this.HtmlRoutePages.volvoFH: this.counterService.postTruckModelClick('Volvo FH'); break;
      case this.HtmlRoutePages.volvoFM: this.counterService.postTruckModelClick('Volvo FM'); break;
      case this.HtmlRoutePages.volvoFMX: this.counterService.postTruckModelClick('Volvo FMX'); break;
    }
    switch (label) {
      case 'pages/truckModel/volvoFE': this.counterService.postTruckModelClick('Volvo FE'); break;
      case 'pages/truckModel/volvoFL': this.counterService.postTruckModelClick('Volvo FL'); break;
    }
  }
}
