import { Component,OnInit, ChangeDetectorRef ,ViewChild, SimpleChanges } from '@angular/core';
import { TruckModelService } from '../../../core/services/truckmodel/truckmodel-service';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvoFH16-page',
  templateUrl: '../model-page.component.html',
  styleUrls: ['../model.scss']
})
export class VolvoFH16PageComponent implements OnInit{
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftCabNavDisabled = false;
  rightCabNavDisabled = false;
  modelName:string='pages/truckModel/volvoFH16';
  imagePath:string='assets/pages/truckModel/volvoFH16.webp';
  imageAltText:string='pages/truckmodel/images/fh16/altimage1';
  modelTitle:string="pages/truckModel/volvoFH16/title";
  modelText:string="pages/truckModel/volvoFH16/classic/text"
  model: string = 'FH16';
  isLoading: boolean = true;
  modelObj = [
    {
      url: 'assets/pages/truckModel/volvoFH16/image1.webp',
      title: 'pages/truckModel/classic/highlights/title1',
      text: 'pages/truckModel/classic/highlights/title1/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage2'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image2.webp',
      title: 'pages/truckModel/classic/highlights/title2',
      text: 'pages/truckModel/classic/highlights/title2/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage3'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image3.webp',
      title: 'pages/truckModel/classic/highlights/title3',
      text: 'pages/truckModel/classic/highlights/title3/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage4'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image2.webp',
      title: 'pages/truckModel/classic/highlights/title4',
      text: 'pages/truckModel/classic/highlights/title4/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage5'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image6.webp',
      title: 'pages/truckModel/classic/highlights/title5',
      text: 'pages/truckModel/classic/highlights/title5/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage6'
    }

  ];
  designObj = [
    {
      url: 'assets/pages/truckModel/volvoFH16/image4.webp',
      title: 'pages/truckModel/exterior',
      text: 'pages/truckModel/exterior/FH16',
      altImg: 'pages/truckmodel/images/fh16/altimage7'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image5.webp',
      title: 'pages/truckModel/interior',
      text: 'pages/truckModel/interior/FH16',
      altImg: 'pages/truckmodel/images/fh16/altimage8'
    },
  ]
  cabObj = [
    {
      url: 'assets/pages/truckModel/volvoFH16/engine1.webp',
      title: 'pages/truckModel/cab/option1',
      text: 'pages/truckModel/cab/option1/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage9'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/engine2.webp',
      title: 'pages/truckModel/cab/option2',
      text: 'pages/truckModel/cab/option2/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage10'

    },
    {
      url: 'assets/pages/truckModel/volvoFH16/engine3.webp',
      title: 'pages/truckModel/cab/option3',
      text: 'pages/truckModel/cab/option3/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage11'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/engine4.webp',
      title: 'pages/truckModel/cab/option4',
      text: 'pages/truckModel/cab/option4/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage12'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/engine5.webp',
      title: 'pages/truckModel/cab/option5',
      text: 'pages/truckModel/cab/option5/text1',
      altImg: 'pages/truckmodel/images/fh16/altimage13'
    }
  ]
  engineObj = [
    {
      name: 'pages/truckModel/engines',
      categories: [
        {
          title: 'pages/truckModel/engine1',
          colums: [
            { column1: 'pages/truckModel/engine1/power1', column2: '550 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine1/power2', column2: '2900 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine2',
          colums: [
            { column1: 'pages/truckModel/engine2/power1', column2: '650 ', column3: 'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine2/power2', column2: '3150 ', column3: 'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine3',
          colums: [
            { column1: 'pages/truckModel/engine3/power1', column2: '750 ', column3: 'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine3/power2', column2: '	3550 ', column3: 'pages/truckModel/engines/nm' }
          ]
        }
      ],
      text:'pages/truckModel/engine/text',
      type:'pages/truckModel/volvoFH16'
    }
  ]

  @ViewChild('slider', { static: false }) slider: any;
  @ViewChild('sliderCab', { static: false }) sliderCab: any;
  constructor(
    public TruckModelService: TruckModelService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService,
    private cdr: ChangeDetectorRef
  ) {
  }
  ngOnInit()
  {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('truckModel/volvofh16/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofh16/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofh16/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('truckModel/volvofh16/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('truckModel/volvofh16/metaDescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('truckModel/volvofh16/altImage'.toLowerCase())
    });

    //this.breadCrumbService.updateBreadcrumb('Truck Models' , 'Volvo FH16', HtmlRoutePages.truckModels);
    }
    onImageLoad(): void {
        this.isLoading = false
        this.triggerNavStatusCheck();
    }

    ngAfterViewInit() {
      // Force boundary detection after the view initializes
      this.cdr.detectChanges(); // Trigger change detection
  
      if (this.slider) {
        this.slider.checkNavStatus();
      }
      if (this.sliderCab) {
        this.sliderCab.checkNavStatus();
      }
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes['modelObj']) {
        this.onSliderBoundaryChanges();
      }
      if (changes['cabObj']) {
        this.onSliderCabBoundaryChanges();
      }
      // Always trigger boundary check after changes
      this.triggerNavStatusCheck();
    }

    onSliderBoundaryChanges() {
      setTimeout(() => {
          if (this.slider) {
              this.slider.checkNavStatus();
          }
      }, 100);
    }

    onSliderCabBoundaryChanges() {
        setTimeout(() => {
            if (this.sliderCab) {
                this.sliderCab.checkNavStatus();
            }
        }, 100);
    }

    triggerNavStatusCheck() {
      if (this.slider) {
        this.slider.checkNavStatus();
      }
      if (this.sliderCab) {
        this.sliderCab.checkNavStatus();
      }
    }
}
