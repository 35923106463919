import { Component,OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';

import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { Company, DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { getDistance } from 'geolib';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { RegionalSettingsState } from '~/core/store/regionalSettings/regionalSettings.state';
import { LocalesService } from '~/core/store/locales/locales.service';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { CountrySettingsStateInterface, RegionalSettingsStateInterface, RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { HtmlRoutePages } from '~/app-routing.model';
import { LocalesStateCountry } from '~/core/store/locales/locales.model';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
    selector: 'volvo-selected-page',
    templateUrl: './volvo-selected-page.component.html',
    styleUrls: ['./volvo-selected-page.component.scss'],
})
export class VolvoSelectedPageComponent implements OnInit{
    dealershipLocations: DealerPlaceResult[] = null;
    dealershipLocationsDump: DealerPlaceResult[] = null;
    userLocation: { lat: number; lng: number };
    HtmlRoutePages = HtmlRoutePages;
    leftNavDisabled = false;
    rightNavDisabled = false;
    private s0: Subscription;
    private s1: Subscription;
    path: string;
    private countrySetting: string;
    public volvoSelectedProEnabled: boolean;
    public volvoSelectedAdvancedEnabled: boolean;
    removePadding: boolean;
    imageAltText: string = 'pages/services/images/volvoselected/altimage1';

    public isFinanceEnabled: boolean = false;
    private languageList = ['ba'];
    constructor(
        public dealershipsService: DealershipsService,
        public dialog: MatDialog,
        private titleService: Title,
        private metaTagService: Meta,
        private translateService: TranslateService,
        private router: Router,
        private store: Store,
        private regionService: RegionalSettingsService,
        private localesService: LocalesService,
        private breadCrumbService: BreadcrumbserviceService,
        public assetService: AssetService
    ) {
        this.initSubscriptions();
        this.dealershipsService.getDealerships();
        
    }
    volvoselectedlist =
        [{
            url: 'assets/pages/volvoselected/service1.webp',
            title: 'form/financing',
            text: 'pages/services/volvoselected/financing',
            altImg: 'pages/services/images/volvoselected/altimage2'
        },
        {
            url: 'assets/pages/volvoselected/service2.webp',
            title: 'pages/services/volvoselected/servicecontracts',
            text: 'pages/services/volvoselected/servicecontracts/text',
            altImg: 'pages/services/images/volvoselected/altimage3'
        },
        {
            url: 'assets/pages/volvoselected/service3.webp',
            title: 'pages/services/volvoselected/volvoconnect',
            text: 'pages/services/volvoselected/volvoconnect/text',
            altImg: 'pages/services/images/volvoselected/altimage4'

        }]
    private initSubscriptions(): void {
        this.s0 = combineLatest([this.dealershipsService.dealerships$, this.dealershipsService.userLocation$]).subscribe(
            ([dealershipLocations, userLocation]) => {
                if (dealershipLocations) {
                    this.dealershipLocationsDump = dealershipLocations;
                    this.dealershipLocations = this.dealershipLocationsDump;

                    if (userLocation && userLocation.isAllowed) {
                        this.userLocation = {
                            lat: userLocation.lat,
                            lng: userLocation.lng
                        };

                        this.dealershipLocations = this.dealershipLocationsDump;
                    }
                }
            }
        );

        this.localesService.country$.subscribe((country: LocalesStateCountry) => {
            if (country) {
                if (country.curCountry) {
                    this.countrySetting = country.curCountry.id;
                    this.s1 = this.regionService.countrySettings$.subscribe((settings: CountrySettingsStateInterface) => {
                        if (settings) {
                            const currentCountryHasSettings = settings.countrysSettings.filter(c => c.country === country.curCountry.id)[0] ? true : false;
                            this.volvoSelectedProEnabled = currentCountryHasSettings ? settings.countrysSettings.filter(c => c.country === country.curCountry.id)[0].selectedProEnabled : true;
                            this.volvoSelectedAdvancedEnabled = currentCountryHasSettings ? settings.countrysSettings.filter(c => c.country === country.curCountry.id)[0].selectedAdvancedEnabled : true;
                            this.removePadding = !this.volvoSelectedProEnabled && !this.volvoSelectedAdvancedEnabled;
                        }
                    });
                    this.enableFinanceByCountryLanguages();
                } else this.countrySetting = null;
            }
        });
    }
    leftBoundStat(reachesLeftBound: boolean) {
        this.leftNavDisabled = reachesLeftBound;
    }

    rightBoundStat(reachesRightBound: boolean) {
        this.rightNavDisabled = reachesRightBound;
    }
    enableFinanceByCountryLanguages() {
        if (this.languageList.includes(this.countrySetting)) {
            this.isFinanceEnabled = false;
        } else {
            this.isFinanceEnabled = true;
        }
    }
    
    ngOnInit(): void {
        this.router.events.subscribe(() => {
          this.titleService.setTitle(this.translateService.instant('aboutus/metaTitle'.toLowerCase()));
          this.metaTagService.updateTag({
            name: 'og:description',
            content: this.translateService.instant('aboutus/metaDescription'.toLowerCase())
          });
          this.metaTagService.updateTag({
            name: 'og:image',
            alt: 'alt',
            content: this.translateService.instant('aboutus/altImage'.toLowerCase())
          });
        });
        this.titleService.setTitle(this.translateService.instant('services/volvoselected/metatitle'.toLowerCase()));
        this.metaTagService.updateTag({
          name: 'og:description',
          content: this.translateService.instant('services/volvoselected/metaDescription'.toLowerCase())
        });
        this.metaTagService.updateTag({
          name: 'og:image',
          alt: 'alt',
          content: this.translateService.instant('services/volvoselected/altImage'.toLowerCase())
        });

        //this.breadCrumbService.updateBreadcrumb('Services' , 'Volvo Selected', HtmlRoutePages.services);
    }
}
