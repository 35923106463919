import { Component,OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ScreenUtils } from 'src/app/core/utils/screen-utils/screen-utils';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-vehicle-offer-approved-page',
  templateUrl: './vehicle-offer-volvo-approved-page.component.html',
  styleUrls: ['./vehicle-offer-volvo-approved-page.component.scss']
})
export class VolvoVehicleOfferApprovedPageComponent implements OnInit{ 
  HtmlRoutePages: HtmlRoutePages;
  isMobileOrTablet: boolean = false;
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private breadCrumbService: BreadcrumbserviceService,
    private globalUtils: GlobalUtils,
    private deviceService: DeviceDetectorService,
    public assetService: AssetService) {
      if (this.globalUtils.isBrowser() && (this.deviceService.isMobile() || this.deviceService.isTablet())) {
        this.isMobileOrTablet = true;
      }
    }
  ngOnInit(): void {
    
    this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/approved/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/vehicleoffers/approved/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/vehicleoffers/approved/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/vehicleoffers/approved/altImage'.toLowerCase())
    });

    //if(this.isMobileOrTablet)
      //this.breadCrumbService.updateBreadcrumb('Vehicle Offers' , 'Volvo Approved', HtmlRoutePages.vehicleOffers);
  }
  approvedList = [
    {
      type: 'uk/pages/vehicleOffer/approved/title1',
      label: [
        { name: 'uk/pages/vehicleOffer/approved/title1/text1'},
        { name: 'uk/pages/vehicleOffer/approved/title1/text2' },
        { name: 'uk/pages/vehicleOffer/approved/title1/text3' },
        { name: 'uk/pages/vehicleOffer/approved/title1/text4' },
        { name: 'uk/pages/vehicleOffer/approved/title1/text5' },
        { name: 'uk/pages/vehicleOffer/approved/title1/text6' },
        { name: 'services/premiumquality/predelivery' },
        { name: 'services/premiumquality/vospschedule' }
      ]
    },
    {
      type:'uk/pages/vehicleOffer/approved/title2',
      label:[
        {name:'uk/pages/vehicleOffer/approved/title2/text1'},
        {name:'uk/pages/vehicleOffer/approved/title2/text2'},
        {name:'uk/pages/vehicleOffer/approved/title2/text3'},
        {name:'uk/pages/vehicleOffer/approved/title2/text4'},
      ]
    },
    {
      type:'uk/pages/vehicleOffer/approved/title3',
      label:[
        {name:'uk/pages/vehicleOffer/approved/title3/text1'},
        {name:'uk/pages/vehicleOffer/approved/title3/text2'},
        {name:'uk/pages/vehicleOffer/approved/title3/text3'},
        {name:'uk/pages/vehicleOffer/approved/title3/text4'},
      ]
    },
  ];

  openFactSheet(): void {
    const factSheetUrl = 'assets/files/UT Volvo Approved Fact Sheet - April 2024.pdf';
    window.open(factSheetUrl, '_blank'); // Opens in a new tab
  }
}
