import { Component, OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router} from '@angular/router';
import { ScreenUtils } from '../../../core/utils/screen-utils/screen-utils';
import { BreadcrumbService } from 'angular-crumbs-2';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-service-economy-page',
  templateUrl: './service-economy-page.component.html',
  styleUrls: ['./service-economy-page.component.scss']
})
export class VolvoServiceEconomyPageComponent implements OnInit {
  HtmlRoutePages: HtmlRoutePages;
  isTablet: boolean = false;
  path: string = '';
  region:string;
  regionEnum=regionEnum;
  private s0: Subscription;
  economyList = [
    {
      type: 'uk/pages/services/volvoEconomy/title1',
      label: [
        { name: 'uk/pages/services/volvoEconomy/title1/text1' },
        { name: 'uk/pages/services/volvoEconomy/title1/text2' },
        { name: 'uk/pages/services/volvoEconomy/title1/text3' },
        { name: 'uk/pages/services/volvoEconomy/title1/text4' },
        { name: 'uk/pages/services/volvoEconomy/title1/text5' },
        { name: 'uk/pages/services/volvoEconomy/title1/text6' },
        { name: 'uk/pages/services/volvoEconomy/title1/text7' }
      ],
      excluding: 'uk/pages/services/volvoEconomy/title1/Excluding'
    },
    {
      type: 'uk/pages/services/volvoEconomy/title2',
      label: [
        { name: 'uk/pages/services/volvoEconomy/title2/text1' },
        { name: 'uk/pages/services/volvoEconomy/title2/text2' },
        { name: 'uk/pages/services/volvoEconomy/title2/text3' },
        { name: 'uk/pages/services/volvoEconomy/title2/text4' }
      ],
      excluding: 'uk/pages/services/volvoEconomy/title2/Excluding'
    },
    {
      type: 'uk/pages/services/volvoEconomy/title3',
      label: [
        { name: 'uk/pages/services/volvoEconomy/title3/text1' },
        { name: 'uk/pages/services/volvoEconomy/title3/text2' },
        { name: 'uk/pages/services/volvoEconomy/title3/text3' },
        { name: 'uk/pages/services/volvoEconomy/title3/text4' }
      ],
      excluding: 'uk/pages/services/volvoEconomy/title3/Excluding'
    },
    {
      type: 'uk/pages/services/volvoEconomy/title4',
      label: [
        { name: 'uk/pages/services/volvoEconomy/title4/text1' },
        { name: 'uk/pages/services/volvoEconomy/title4/text2' },
        { name: 'uk/pages/services/volvoEconomy/title4/text3' },
        { name: 'uk/pages/services/volvoEconomy/title4/text4' },
        { name: 'uk/pages/services/volvoEconomy/title4/text5' }
      ],
      excluding: 'uk/pages/services/volvoEconomy/title4/Excluding'
    }
  ];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private regionalSettingsService:RegionalSettingsService,
    public localesService: LocalesService,
    private breadCrumbService: BreadcrumbserviceService,
    private localizeRouterService: LocalizeRouterService,
    public assetService: AssetService
  ) {
  }

  ngOnInit(): void {
    this.s0 = this.localesService.region$.subscribe(results => {
      this.region = results.curRegion.id;
    })
    if (ScreenUtils.isTablet()) this.isTablet = true;
    if (this.isTablet) {
      this.breadcrumbService.changeBreadcrumb(
        this.route.snapshot,
        'Warranty Volvo Economy'
      );
    }
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/volvoeconomy/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/volvoeconomy/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/volvoeconomy/altImage'.toLowerCase())
    });
    
    this.updateBreadcrumb();
  }
  routePage(): void {
    this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.economyFAQ)], { relativeTo: this.route });
  }
 

  updateBreadcrumb(): void {
    if(this.region === regionEnum.TX){
      //this.breadCrumbService.updateBreadcrumb('Services' , 'Export (Worldwide)', HtmlRoutePages.services);
    }
    else{
      //this.breadCrumbService.updateBreadcrumb('Services' , 'Volvo Economy', HtmlRoutePages.services);
    }
  }

  openFactSheet(url: string): void {
    window.open(url, '_blank');
   }
}
