import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as cloneDeep from "lodash/fp/cloneDeep";

import { Bucket, BucketAggregations, BucketsData, stockLocationAddress, StockLocationCountry } from "~/core/store/buckets/buckets.model";
import { SortOptions, SearchStateFilter, SearchStep } from "~/core/store/search/search.model";
import { MatOption } from "@angular/material/core";
import { MatSelectionList } from "@angular/material/list";
import { ViewChild } from "@angular/core";
import { StockLocation } from "~/core/store/dealerships/dealerships.model";
import { countries } from '../../../core/constants/country'
import { RegionalSettingsService } from "~/core/store/regionalSettings/regionalSettings.service";
import { Store } from "@ngxs/store";
import { AppState } from "~/core/store";
import { RegionSettings } from "~/core/store/regionalSettings/regionalSettings.model";
@Component({
    selector: "volvo-filter-radio-search",
    templateUrl: "./filter-radio-search.component.html",
    styleUrls: ["./filter-radio-search.component.scss"],
})
export class FilterRadioSearchComponent implements AfterViewInit {
    stepInput: string;
    _step: SearchStep;
    selectedOption: SortOptions;
    s1: any;
    s2: any;
    initValue: boolean;
    allowUpdate: boolean;
    countryList = countries;
    region: string;
    regionalSettings: RegionSettings;

    @Input() aggregationTypeName: string;

    previousSelected: boolean;
    currentlySelected: boolean;
    wasJustDeselected: boolean;
    sortBucketsList: Bucket[];
    @Input() set searchFilter(searchFilter: SearchStateFilter) {
        if (searchFilter) {
            this.previousSelected = this.currentlySelected;
            this.currentlySelected = searchFilter.steps[this.aggregationTypeName].isSelected;
            this.wasJustDeselected = this.previousSelected && !this.currentlySelected;
        }
    }

    @Input() isSuperstructure = false;
    @Input() isFuelType = false;

    @Input() set step(step: SearchStep) {
        this._step = step;
        this.stepInput = step.value === "All" ? "" : (step.value as string);
        if (this.stepInput === '' && this.initValue) {
            this.selectionList.deselectAll();
        }
    }

    bucketsList: Bucket[];

    requestingFilter = "";
    expandingFilter = "";

    @Input() set aggregations(aggregations: BucketAggregations) {

        if (aggregations) {
            if (this.bucketsList === undefined) {
                if (this.aggregationTypeName === "stockLocationCountry") {
                    let stockLocationAggs = cloneDeep(aggregations[this.aggregationTypeName]);
                    for (let i = 0; i <= this.countryList.length - 1; i++) {
                        if (stockLocationAggs.totals.filter(x => x.key === this.countryList[i].label).length === 0 && this.countryList[i].label !== 'South Africa') {
                            stockLocationAggs.totals.push({ key: this.countryList[i].label, doc_count: 0 })
                        }
                    }
                    this.bucketsList = cloneDeep(stockLocationAggs.totals);
                }
                else {
                    this.bucketsList = cloneDeep(aggregations[this.aggregationTypeName].totals);
                }
            }
            this.createNewsortList();
            let aggs = cloneDeep(aggregations[this.aggregationTypeName]);

            this.requestingFilter = aggregations.requestingFilter;
            this.expandingFilter = aggregations.expandingFilter;
            this.updateDocumentCounts(aggs.buckets, this.bucketsList);
        }
    }

    @Output() filterChange = new EventEmitter();
    @ViewChild('selectionList') selectionList: MatSelectionList;

    constructor(private regionalSettingsService: RegionalSettingsService, store: Store) {
        this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
    }

    ngAfterViewInit(): void { }

    onChange(): void {
        this.initValue = true;
        this.expandingFilter = "";
        this.stepInput = this.selectionList.selectedOptions.selected.map(s => s.value).toString();
        this.stepInput === "0" ? this.stepInput = "All" : this.stepInput = this.selectionList.selectedOptions.selected.map(s => s.value).join(";");

        if (this.stepInput.length > 1 && this.stepInput.includes("0")) {
            //Not sure why we have this condition so commenting it for time being
            //this.stepInput = this.stepInput.replace("0;", "");
            this.filterChange.emit({
                ...this._step,
                isSelected: true,
                value: this.stepInput
            });
        } else {
            this.filterChange.emit({
                ...this._step,
                isSelected: true,
                value: this.stepInput
            });
        }
    }
    toggleAllSelection() {
        this.stepInput = this.selectionList.selectedOptions.selected.map(s => s.value).toString();
        if (this.stepInput === "0") {
            this.selectionList.selectAll();
        } else {
            this.selectionList.deselectAll();
            this.stepInput = "";
            this.filterChange.emit({
                ...this._step,
                isSelected: true,
                value: ''
            });
        }
    }

    checkSelected(value: string, item: string) {
        if (value) {
            const parts = value.split(';');
            const decision = parts.includes(item);
            return decision;
        }
        return false;
    }

    updateCheckedItems(aggregations: Bucket[]) {
        this.selectionList.selectedOptions.select()
    }

    updateDocumentCounts(aggregations: Bucket[] | StockLocationCountry[], totals: Bucket[]) {
        if (this.requestingFilter === "superstructureType") {
            for (const total of totals) {
                for (const agg of (aggregations as Bucket[])) {
                    agg.total = totals.filter(t => t.key === agg.key)[0].doc_count;
                }
                if ((aggregations as Bucket[]).filter(f => f.key === total.key).length === 0) {
                    aggregations.push({ key: total.key, doc_count: 0, total: total.doc_count });
                }
            }
        }

        if (this.searchFilter) {
            this.wasJustDeselected = this.searchFilter.steps[this.aggregationTypeName].isExpanded;
        }

        if (this.requestingFilter !== this.aggregationTypeName || this.wasJustDeselected) {
            for (const total of totals) {
                if ((aggregations as StockLocationCountry[])[0] && (aggregations as StockLocationCountry[])[0].StockLocationCity) {
                    for (const agg of (aggregations as StockLocationCountry[])) {
                        agg.total = (aggregations as StockLocationCountry[]).filter(t => t.key === agg.key)[0].doc_count;
                    }
                    if ((aggregations as StockLocationCountry[]).filter(f => f.key === total.key).length === 0) {
                        aggregations.push({ key: total.key, doc_count: 0, total: total.doc_count });
                    }
                } else {
                    for (const agg of (aggregations as Bucket[])) {
                        agg.total = totals.filter(t => t.key === agg.key)[0].doc_count;
                    }
                    if ((aggregations as Bucket[]).filter(f => f.key === total.key).length === 0) {
                        if (this.expandingFilter === 'advertisementCategory' || this.requestingFilter === 'offerAndWarranty') {
                            aggregations.push({ key: total.key, doc_count: total.doc_count, total: total.doc_count });
                        }
                        else {
                            aggregations.push({ key: total.key, doc_count: 0, total: total.doc_count });
                        }
                    }
                }
            }

            this.bucketsList = cloneDeep(aggregations);
            this.createNewsortList();
        }
    }

    keyName(key: string) {
        if (key === "Selected")
            return "Volvo Selected, 24 months"
        if (key === "Approved")
            return "Volvo Approved, 12 months"
        if (key === "Economy")
            return "Volvo Economy, 6 months"
        if (key === "Remaining Warranty")
            return "Remaining Warranty"
        return key;
    }

    createNewsortList() {
        this.sortBucketsList = this.bucketsList?.sort((a, b) => {
            if (a.key === "Remaining Warranty") {
                return -1;
            }
            if (b.key === "Remaining Warranty") {
                return 1;
            }
            if (a.key === "Selected") {
                return -1;
            }
            if (b.key === "Selected") {
                return 1;
            }
            if (a.key === "Approved") {
                return -1;
            }
            if (b.key === "Approved") {
                return 1;
            }
            if (a.key === "Economy") {
                return -1;
            }
            if (b.key === "Economy") {
                return 1;
            }
            return 0;
        });
    }
}
