import { Component,OnInit, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { TruckModelService } from '../../../core/services/truckmodel/truckmodel-service';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvoFH-page',
  templateUrl: '../model-page.component.html',
  styleUrls: ['../model.scss']
})
export class VolvoFHPageComponent implements OnInit {
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftCabNavDisabled = false;
  rightCabNavDisabled = false;
  modelName:string='pages/truckModel/volvoFH';
  imagePath:string='assets/pages/truckModel/volvoFH.webp';
  imageAltText:string='pages/truckmodel/images/fh/altimage1';
  modelTitle:string="pages/truckModel/volvoFH/title";
  modelText:string="pages/truckModel/volvoFH/classic/text"
    model: string = 'FH';
    isLoading: boolean = true;
  modelObj = [
    {
      url: 'assets/pages/truckModel/volvoFH16/image1.webp',
      title: 'pages/truckModel/classic/highlights/title1',
      text: 'pages/truckModel/classic/highlights/title1/text1',
      altImg: 'pages/truckmodel/images/fh/altimage2'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image2.webp',
      title: 'pages/truckModel/classic/highlights/title2',
      text: 'pages/truckModel/classic/highlights/title2/text1',
      altImg: 'pages/truckmodel/images/fh/altimage3'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/image3.webp',
      title: 'pages/truckModel/classic/highlights/title6',
      text: 'pages/truckModel/classic/highlights/title6/text1',
      altImg: 'pages/truckmodel/images/fh/altimage4'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image2.webp',
      title: 'pages/truckModel/classic/highlights/title4',
      text: 'pages/truckModel/classic/highlights/title4/text1',
      altImg: 'pages/truckmodel/images/fh/altimage5'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/image6.webp',
      title: 'pages/truckModel/classic/highlights/title7',
      text: 'pages/truckModel/classic/highlights/title7/text1',
      altImg: 'pages/truckmodel/images/fh/altimage6'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image6.webp',
      title: 'pages/truckModel/classic/highlights/title5',
      text: 'pages/truckModel/classic/highlights/title5/text1',
      altImg: 'pages/truckmodel/images/fh/altimage7'
    }
  ];
  designObj = [
    {
      url: 'assets/pages/truckModel/volvoFH/image4.webp',
      title: 'pages/truckModel/exterior',
      text: 'pages/truckModel/exterior/FH',
      altImg: 'pages/truckmodel/images/fh/altimage8'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/image7.webp',
      title: 'pages/truckModel/interior',
      text: 'pages/truckModel/interior/FH',
      altImg: 'pages/truckmodel/images/fh/altimage9'
    },
  ]
  cabObj = [
    {
      url: 'assets/pages/truckModel/volvoFH/engine1.webp',
      title: 'pages/truckModel/cab/option1',
      text: 'pages/truckModel/cab/option1/text1',
      altImg: 'pages/truckmodel/images/fh/altimage10'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/engine2.webp',
      title: 'pages/truckModel/cab/option2',
      text: 'pages/truckModel/cab/option2/text1',
      altImg: 'pages/truckmodel/images/fh/altimage11'

    },
    {
      url: 'assets/pages/truckModel/volvoFH/engine3.webp',
      title: 'pages/truckModel/cab/option3',
      text: 'pages/truckModel/cab/option3/text1',
      altImg: 'pages/truckmodel/images/fh/altimage12'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/engine4.webp',
      title: 'pages/truckModel/cab/option4',
      text: 'pages/truckModel/cab/option4/text1',
      altImg: 'pages/truckmodel/images/fh/altimage13'
    },
    {
      url: 'assets/pages/truckModel/volvoFH/engine4.webp',
      title: 'pages/truckModel/cab/option5',
      text: 'pages/truckModel/cab/option5/text1',
      altImg: 'pages/truckmodel/images/fh/altimage14'
    }
  ]
  engineObj = [
    {
      name: 'pages/truckModel/engines',
      categories: [
        {
          title: 'pages/truckModel/engine11',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '420 ', column3:'pages/truckModel/engines/hp'},
            { column1: 'pages/truckModel/engine7/power2', column2: '2100 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine12',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '460 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine9/power1', column2: '2300  ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine13',
          colums: [
            { column1: 'pages/truckModel/engine13/power1', column2: '460  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine13/power2', column2: '2600 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine10',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '500  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine10/power1', column2: '2500 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine14',
          colums: [
            { column1: 'pages/truckModel/engine13/power1', column2: '500  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine13/power2', column2: '2800 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine15',
          colums: [
            { column1: 'pages/truckModel/engine15/power1', column2: '540  ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power2', column2: '2600 ', column3:'pages/truckModel/engines/nm'}
          ]
        }
      ],
      text:'pages/truckModel/engine/text/fh',
      type:'pages/truckModel/volvoFH'
    },
    {
      name: 'pages/truckModel/lngengines',
      categories: [
        {
          title: 'pages/truckModel/lngengine1',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '420 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine6/power1', column2: '2100 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/lngengine2',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '460 ',column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power3', column2: '2300 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
      ],
      text:'pages/truckModel/engine/text/fh',
      type:'pages/truckModel/volvoFH'
    }
  ]

  @ViewChild('slider', { static: false }) slider: any;
  @ViewChild('sliderCab', { static: false }) sliderCab: any;
  constructor(
    public TruckModelService: TruckModelService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService,
    private cdr: ChangeDetectorRef
  ) {
    
  }
  ngOnInit()
  {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('truckModel/volvofh/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofh/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofh/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('truckModel/volvofh/metatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('truckModel/volvofh/metaDescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('truckModel/volvofh/altImage'.toLowerCase())
    });
    //this.breadCrumbService.updateBreadcrumb('Truck Models' , 'Volvo FH', HtmlRoutePages.truckModels);
    }
    onImageLoad(): void {
      this.isLoading = false
      this.triggerNavStatusCheck();
  }

  ngAfterViewInit() {
    // Force boundary detection after the view initializes
    this.cdr.detectChanges(); // Trigger change detection

    if (this.slider) {
      this.slider.checkNavStatus();
    }
    if (this.sliderCab) {
      this.sliderCab.checkNavStatus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modelObj']) {
      this.onSliderBoundaryChanges();
    }
    if (changes['cabObj']) {
      this.onSliderCabBoundaryChanges();
    }
    // Always trigger boundary check after changes
    this.triggerNavStatusCheck();
  }

  onSliderBoundaryChanges() {
    setTimeout(() => {
        if (this.slider) {
            this.slider.checkNavStatus();
        }
    }, 100);
  }

  onSliderCabBoundaryChanges() {
      setTimeout(() => {
          if (this.sliderCab) {
              this.sliderCab.checkNavStatus();
          }
      }, 100);
  }

  triggerNavStatusCheck() {
    if (this.slider) {
      this.slider.checkNavStatus();
    }
    if (this.sliderCab) {
      this.sliderCab.checkNavStatus();
    }
  }
  
}
