<section class="selected">
    <div class="tabMobSidePadding">
        <div class="selected-selectedDiv">
            <div class="tabMobHeroMargin">
                <img [src]="assetService.getAssetUrl('assets/pages/vehicleOffers/UTSelected.webp')" class="selected-selectedImg" alt=""/>
            </div>
            <div class="selected-title">
                <span>{{ "uk/components/footer/volvoSelected" | lowercase | translate }}</span>
            </div>
            <div class="selected-text">
                <span>{{ "uk/pages/vehicleOffer/selectedText1" | lowercase | translate }}</span>
            </div>
            <div class="selected-text">
                <span class="selected-text-medium">{{ "uk/pages/vehicleOffer/selectedText2" | lowercase | translate }}</span>{{ "uk/pages/vehicleOffer/selectedText3" | lowercase | translate }}
            </div>
            <div class="selected-text">
                <span>{{ "uk/pages/vehicleOffer/selectedText4" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="selected-list">
            <ul class="selected-list-title" *ngFor="let list of selectedList">
                {{
        list.type | lowercase | translate
                }}
                <li *ngFor="let label of list.label">
                    {{ label.name | lowercase | translate }}
                </li>
            </ul>
        </div>
        <div>
            <span class="selected-disClaimer">{{"uk/pages/vehicleOffer/disclaimer" | lowercase  |translate}}</span>
        </div>
        <div class="volvoFactSheet" id="approved">
            <button
              class="navigation-buttonFact"
              (click)="openFactSheet()"
              [attr.aria-label]=" 'uk/pages/services/selectedfact' | lowercase | translate "
            >
              <span class="navigation-button-text">
                {{ "uk/pages/services/selectedfact" | lowercase | translate }}
              </span>
              <img
                [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')"
                alt="{{ 'pages/truckmodel/readmore' | lowercase | translate }}"
              />
            </button>
        </div>
        </div>
</section>
<volvo-footer></volvo-footer>
