import { Component,OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenUtils } from '../../../core/utils/screen-utils/screen-utils';
import { BreadcrumbService } from 'angular-crumbs-2';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { regionEnum } from '../../../core/declaration/declaration';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Subscription } from 'rxjs';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-approved-service-page',
  templateUrl: './volvo-approved-page.component.html',
  styleUrls: ['./volvo-approved-page.component.scss']
})

export class VolvoApprovedServicePageComponent implements OnInit{
  HtmlRoutePages:HtmlRoutePages;
  isTablet: boolean = false;
  toogleClass:boolean=true;
  region:string;
  regionEnum=regionEnum;
  private s0: Subscription;
  constructor(private route: ActivatedRoute, private router: Router,private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,private metaTagService: Meta, 
    private regionalSettingsService:RegionalSettingsService,
    public localesService: LocalesService,
    private breadCrumbService: BreadcrumbserviceService,
    private localizeRouterService: LocalizeRouterService,
    public assetService: AssetService) {}
  approvedList = [
    {
      type: 'uk/pages/services/volvoSelected/title1',
      name:  'uk/pages/services/volvoSelected/title1/text1' 
    },
    {
      type: 'uk/pages/services/volvoSelected/title2',
      name: 'uk/pages/services/volvoSelected/title2/text1'
    },
    {
      type: 'uk/pages/services/volvoSelected/title3',
      name:  'uk/pages/services/volvoSelected/title3/text1'
    }
  ];
  imageObject = [
    {
      image: '/assets/pages/services/24Months.webp'
    },
    {
      image: '/assets/pages/services/12Months.webp'
    }
  ];
  ngOnInit(): void {
    this.s0 = this.localesService.region$.subscribe(results => {
      this.region = results.curRegion.id;
    })
    if (ScreenUtils.isTablet()) this.isTablet = true;
    if(this.isTablet)
    {
      this.breadcrumbService.changeBreadcrumb(this.route.snapshot, 'Warranty Volvo Selected & Approved');
    }
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/services/volvoselected/metatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/services/volvoselected/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/services/volvoselected/altImage'.toLowerCase())
    });
    // Additional traditional meta tags for search engines
    this.metaTagService.updateTag({
      name: 'description',
      content: this.translateService.instant('uk/services/volvoselected/metadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: this.translateService.instant('uk/services/volvoselected/metakeywords'.toLowerCase())
    });

    this.updateBreadcrumb();
  }
  routePage(): void { 
    this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.approvedFAQ)], { relativeTo: this.route });
  }
  onIndexChanged(idx) {
    if(idx===1)
      this.toogleClass=false;
    else  
      this.toogleClass=true;
  }
  updateBreadcrumb(): void {
    if(this.isTablet)
    {
      if(this.region === regionEnum.TX){
        //this.breadCrumbService.updateBreadcrumb('Services' , 'Export (Worldwide)', HtmlRoutePages.services);
      }
      else{
        //this.breadCrumbService.updateBreadcrumb('Services' , 'Volvo Selected & Approved', HtmlRoutePages.services);
      }
    }
  }
  exportObject = [
    {
      image: '/assets/pages/services/rorotx.webp',
      title:'uk/pages/services/volvoeconomy/title1',
      text:'uk/pages/services/volvoeconomy/text2'
    },
    {
      image: '/assets/pages/services/Containerisation.webp',
      title:'uk/pages/services/volvoeconomy/title2',
      text:'uk/pages/services/volvoeconomy/title2/text1'
    },
  ];

  openFactSheet(url: string): void {
   window.open(url, '_blank');
  }

}
