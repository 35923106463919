<div class="truckPage" *ngIf="truck">
  <div>
      <div class="StockItemB" >
          <div class="rightDiv">
              
              <div class="print-image">
                  <img *ngIf="truck.pictures" [src]="truck.pictures[0].filenameMedium | volvoCdn " alt="">

                  <div class="hotLine" *isUKSite>
                      <span class="hotLine-number">Hotline: 0844 579 1198</span>

                      <!-- <div class="hotLine-stockLocation">
            <i class="fas fa-map-marker-alt"></i>
            {{ truck.stockLocation.stockLocationName }}
        </div> -->
                  </div>
                  <div class="hotLine" *isTXSite>
                      <span class="hotLine-number">Hotline: +44 844 579 1222</span>
                  </div>

                  <div class="ContentWp-left-salesRep" *ngIf="isSelectedSite">
                      <div class="ContentWp-left-salesRepText">{{ this.salespersons[0].salespersonName }}</div>
                      <div style="padding-top: 5px;">{{ this.info }}</div>
                      <div class="ContentWp-left-salesMargin">{{ truck.stockLocation.stockLocationName }}</div>
                      <div>
                          {{ this.salespersons[0].telephoneNumber }}
                      </div>
                  </div>

                  <!--Dealership information-->
                  <div class="ContentWp-left-salesRep" *isSelectedSite>
                      <div class="ContentWp-left-salesRepText">{{ 'form/dealership' | lowercase | translate }}</div>
                      <div class="ContentWp-left-salesMargin">{{ truck.stockLocation.name }}</div>
                      <div class="ContentWp-left-salesMargin">{{ truck.stockLocation.address }}</div>
                      <div class="ContentWp-left-salesMargin">{{ truck.stockLocation.postalCode }} {{ truck.stockLocation.city }}</div>
                      <div>{{ truck.stockLocation.country }}</div>
                  </div>

                  <div *isTXSite #mapContainer id="map" class="mapSection"></div>
                  <img [src]="mapImage" class="mapSection" *ngIf="mapImage" alt="Map">
              </div>
              <div >
                  <div class="approvedContent" *isUKNSSite>
                      <div class="approvedOffer">
                          <div *ngIf="truck?.advertisementCategory=='Approved'" >
                              <!-- <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-12-icon.webp')" alt="" /> -->
                              <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/approvedoffer' | lowercase | translate }}</h6>
                              <ul class="approvedOffer-offerText listClass">
                                  <li> {{ 'uk/truck/offer/approvedofferitem1' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem2' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem3' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem4' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem5' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem6' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem7' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem8' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/approvedofferitem9' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                              </ul>
                              <div class="approvedOffer-subTextofferContract">
                                  {{ 'uk/truck/offer/approvedoffertext' | lowercase | translate }}
                              </div>
                          </div>
                          <div *ngIf="truck?.advertisementCategory=='Selected'" >
                                  <!-- <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-24-icon.webp')" alt="" /> -->
                              <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/selectedoffer' | lowercase | translate }}</h6>
                              <ul class="approvedOffer-offerText listClass">
                                  <li> {{ 'uk/truck/offer/selectedofferitem1' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem2' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem3' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem4' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem5' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem6' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem7' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem8' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/selectedofferitem9' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                              </ul>
                              <div class="approvedOffer-subTextofferContract">
                                  {{ 'uk/truck/offer/selectedoffertext' | lowercase | translate }}
                              </div>
                          </div>
                          <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" >
                              <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/remainingoffer' | lowercase | translate }}</h6>
                              <ul class="approvedOffer-offerText listClass">
                                  <li> {{ 'uk/truck/offer/remainingofferitem1' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/remainingofferitem2' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/remainingofferitem3' | lowercase | translate }}</li>
                                  <!-- <li> {{ 'uk/truck/offer/' | lowercase | translate }}</li> -->
                                  <li> {{ 'uk/truck/offer/remainingofferitem5' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/remainingofferitem6' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/remainingofferitem7' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/remainingofferitem8' | lowercase | translate }}</li>
                              </ul>
                              <div class="approvedOffer-subTextofferContract">
                                  {{ 'uk/truck/offer/remainingoffertext' | lowercase | translate }}
                              </div>
                          </div>
                          <div *ngIf="truck?.advertisementCategory=='Economy'" >
                                  <!-- <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-6-icon.webp')" alt="" /> -->
                              <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/economyoffer' | lowercase | translate }}</h6>
                              <ul class="approvedOffer-offerText listClass">
                                  <li> {{ 'uk/truck/offer/economyofferitem1' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/economyofferitem2' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/economyofferitem3' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/economyofferitem4' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/economyofferitem5' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/offer/economyofferitem6' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                                  <li> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                              </ul>
                              <div class="approvedOffer-subTextofferContract">
                                  {{ 'uk/truck/offer/economyoffertext' | lowercase | translate }}
                              </div>
                          </div>
                      </div>

                      <div class="blueContract">
                          <div>
                              <h6 class="approvedOffer-subHeader" style="color: #ffffff;"><li> {{ 'uk/truck/bluecontract' | lowercase | translate }}</li></h6>
                              <ul class="blueContract-offerText listClass">
                                  <li> {{ 'uk/truck/bluecontractitem1' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem2' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem3' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem4' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem5' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem6' | lowercase | translate }}</li>
                                  <li> {{ 'uk/truck/bluecontractitem7' | lowercase | translate }}</li>
                              </ul>
                              <mat-divider class="approvedOffer-matDivider"></mat-divider>
                              <div>
                                  <div class="blueContract-flexDiv">
                                      <div class="blueContract-text">{{ 'uk/truck/bluecontract/weeklyprice' | lowercase | translate }}:</div>
                                      <div class="blueContract-text">
                                      £ 85.00 *
                                      </div>
                                  </div>
                                  <div class="blueContract-noteText">
                                      <span>
                                          * {{ 'uk/truck/bluecontract/indicativeprice' | lowercase | translate }}
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="vfsContent" *ngIf="showVfs && (depositVal > 0 || monthVal > 0 || totalFinancePayment > 0) && regionSettings !== 'TX'; else noVFS">
                    <div class="vfsContent-title">{{ 'components/truckPageLease/title' | lowercase | translate }}</div>
                    <div>
                        <div class="vfsContent-box">
                            <div class="vfsRow" *ngIf="depositVal > 0">
                                <div class="vfsContent-vfsName">{{ 'components/truckPageLease/slider/titleA' | lowercase | translate }}: </div>
                                <div *isUKNSSite class="vfsContent-vfsValue">£{{depositVal}} + all of the VAT</div>
                                <div *isSelectedSite class="vfsContent-vfsValue">{{depositVal | number: '1.0-0': 'de' }} €</div>
                            </div>
                            <div class="vfsRow" *ngIf="monthVal > 0">
                                <div class="vfsContent-vfsName">{{ 'components/truckPageLease/slider/titleB' | lowercase | translate }}: </div>
                                <div class="vfsContent-vfsValue">{{monthVal}} {{ 'component/truckPageLease/months' | lowercase |
                                  translate }} </div>
                            </div>
                            <div class="vfsRow" *ngIf="totalFinancePayment > 0">
                                <div class="vfsContent-vfsName">{{ 'components/truckPageLease/lease/title' | lowercase | translate }}: </div>
                                <div *isUKNSSite class="vfsContent-vfsValue">£{{totalFinancePayment}} * </div>
                                <div *isSelectedSite class="vfsContent-vfsValue">{{totalFinancePayment | number: '1.0-0': 'de' }} €* </div>
                            </div>
                        </div>
                        <div *ngIf="!defaultDisclaimer" class="vfsRow vfsContent-vfsText">
                            *{{ 'components/truckPageLease/slider/disclamer' | lowercase | translate }}
                        </div>
                        <div *ngIf="defaultDisclaimer" class="vfsRow vfsContent-vfsText">
                            *The calculation is an indicative monthly cost based on the geographical location of the dealership.
                        </div>
                    </div>
                  </div>
                  <ng-template #noVFS >
                    <!-- The if condition is cecked to avaoid the empty space I am creating, the empty space is needed to push the all images 
                        display to nect page but when we have offer types to show we do not need this extra space -->
                      <div class="noVFS" *ngIf="
                      !truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading ||
                      !truck?.specification.vehicleHighlights?.financing?.financingHeading ||
                      !truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading ||
                      !truck?.specification.vehicleHighlights?.warranty?.warrantyHeading
                      "></div>
                  </ng-template>
                  <div *isSelectedSite>
                    <div class="BestTruck-wrap" *ngIf="
                    truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading ||
                    truck?.specification.vehicleHighlights?.financing?.financingHeading ||
                    truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading ||
                    truck?.specification.vehicleHighlights?.warranty?.warrantyHeading
                    ">
                    <div>
                        <div class="Info">
                            <div *ngIf="truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading" class="Info-Item">
                                <div class="Info-Icon">
                                <img [src]="assetService.getAssetUrl('assets/svg/icons/Volvo Selected Pro.png')" />
                                </div>
                                <div class="Info-Text">
                                    <div class="Info-Title">
                                        {{ truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusHeading | lookup }}
                                    </div>
                                    <div class="Info-Description">
                                        {{
                                        (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText | lookup).length != 0
                                        ? (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText | lookup)
                                        : truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText.engPhrase
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="truck?.specification.vehicleHighlights?.financing?.financingHeading" class="Info-Item">
                                <div class="Info-Icon">
                                <img [src]="assetService.getAssetUrl('assets/svg/icons/Financing.png')" />
                                </div>
                                <div class="Info-Text">
                                <div class="Info-Title">
                                    {{
                                    (truck?.specification.vehicleHighlights?.financing.financingHeading | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.financing.financingHeading | lookup)
                                    : truck?.specification.vehicleHighlights?.financing.financingHeading.engPhrase
                                    }}
                                </div>
                                <div class="Info-Description">
                                    {{
                                    (truck?.specification.vehicleHighlights?.financing.financingText | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.financing.financingText | lookup)
                                    : truck?.specification.vehicleHighlights?.financing.financingText.engPhrase
                                    }}
                                </div>
                                </div>
                            </div>
                        
                        </div>
                        <div class="Info">
                            <div *ngIf="truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading" class="Info-Item">
                                <div class="Info-Icon">
                                <img [src]="assetService.getAssetUrl('assets/svg/icons/Low Mileage.png')" />
                                </div>
                                <div class="Info-Text">
                                <div class="Info-Title">
                                    {{
                                    (truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading | lookup)
                                    : truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading.engPhrase
                                    }}
                                </div>
                                <div class="Info-Description">
                                    {{
                                    (truck?.specification.vehicleHighlights?.lowMileage.lowMileageText | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.lowMileage.lowMileageText | lookup)
                                    : truck?.specification.vehicleHighlights?.lowMileage.lowMileageText.engPhrase
                                    }}
                                </div>
                                </div>
                            </div>
                            <div *ngIf="truck?.specification.vehicleHighlights?.warranty?.warrantyHeading" class="Info-Item">
                                <div class="Info-Icon">
                                <img [src]="assetService.getAssetUrl('assets/svg/icons/Warranty.png')" />
                                </div>
                                <div class="Info-Text">
                                <div class="Info-Title">
                                    {{
                                    (truck?.specification.vehicleHighlights?.warranty.warrantyHeading | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.warranty.warrantyHeading | lookup)
                                    : truck?.specification.vehicleHighlights?.warranty.warrantyHeading.engPhrase
                                    }}
                                </div>
                                <div class="Info-Description">
                                    {{
                                    (truck?.specification.vehicleHighlights?.warranty.warrantyText | lookup).length != 0
                                    ? (truck?.specification.vehicleHighlights?.warranty.warrantyText | lookup)
                                    : truck?.specification.vehicleHighlights?.warranty.warrantyText.engPhrase
                                    }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                  </div>
              </div>
          </div>

          <div class="flexTruckDetails">
              <div class="ContentWp-right StockItemB-right">
                  <div class="StockItemB-infoWp">
                      <!-- <div class="StockItemB-infoHeader"> -->
                          <div class="StockItemB-infoHeaderTitle">
                              {{ truck.specification.make | hasValue }} {{ truck.specification.model | hasValue }}
                          </div>
                      <!-- </div> -->
                      <div >
                          <div *ngIf="truck.specification.axleConfiguration" class="labelRow" >
                              <div class="labelName" *ngIf="!truck.specification.isElectric">
                                  {{ truck?.specification.axleConfiguration | lookup }} {{ truck?.specification.horsePower | hasValue }} {{ truck?.specification.category | hasValue }}
                              </div>
                              <div class="labelName" *ngIf="truck.specification.isElectric">
                                {{ truck?.specification.axleConfiguration | lookup }} {{ truck?.specification.category | hasValue }}
                            </div>
                              <!-- <div class="labelValue">
                                  <div *ngIf="truck.advertisementCategory=='Remaining Warranty'">{{truck?.advertisementCategory}}</div>
                                  <div *ngIf="truck.advertisementCategory!='Remaining Warranty'">Volvo {{truck?.advertisementCategory}}</div>
                              </div> -->
                          </div>
                      </div>
                      <div class="labelRow">
                        <div >
                          <div *isSelectedSite>
                            <div *ngIf="!truck.isDeleted" class="StockItemB-infoPrice labelName">
                              <span *ngIf="truck?.stockLocation?.region === 'ZA'; else others">
                                <span *ngIf="truck.ShowPrice && this.langId != 'de'">{{ truck.pricing?.priceExclVat.value | number:
                                  '1.0-0': 'de' }}
                                  <span class="StockItemB-infoPrice-curreny">R </span>
                                </span>
                                <span *ngIf="truck.ShowPrice && this.langId == 'de'">{{ truck.pricing?.priceExclVat.value | number:
                                  '1.0-0': 'de'
                                  }}<span class="StockItemB-infoPrice-curreny">R </span>
                                </span>
                              </span>
                              <ng-template #others>
                                <span *ngIf="truck.ShowPrice && this.langId != 'de'">{{ truck.pricing?.priceExclVatEuro | number: '1.0-0':
                                  'de' }}
                                  <span class="StockItemB-infoPrice-curreny">€</span>
                                </span>
                                <span *ngIf="truck.ShowPrice && this.langId == 'de'">{{ truck.pricing?.priceExclVatEuro | number: '1.0-0':
                                  'de'
                                  }}<span class="StockItemB-infoPrice-curreny">€</span>
                                </span>
                              </ng-template>
                  
                              <span *ngIf="!truck.ShowPrice" class="StockItemB-infoPrice">{{
                                'truck/askPrice' | lowercase | translate
                                }}</span>
                              <span *ngIf="truck.ShowPrice" class="StockItemB-infoPrice-vat">{{
                                'truck/vat' | lowercase | translate
                                }}</span>
                            </div>
                  
                            <div
                              *ngIf="truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck.ShowPrice"
                              class="StockItemB-infoPrice">
                              <b>{{ truck.pricing?.priceExclVat?.value | number: '1.0-0': 'de' }} 
                                {{ truck.pricing?.priceExclVat?.currencyIsoCode3 }}</b>
                            </div>
                          </div>
                          <div *ngIf="!isSelectedSite">
                            <span class="StockItemB-infoPrice labelName">{{
                              'truck/askPrice' | lowercase | translate
                              }}</span>
                          </div>
                        </div>
                        <div class="hotLine-stockLocation labelName">
                            <i class="fas fa-map-marker-alt"></i>
                            {{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 |
                                lookupcountry) :
                                (truck?.stockLocation?.country | hasValue) }} - {{ truck?.stockLocation.city | hasValue }}
                        </div>
                      </div>
                      <!-- <div *ngIf="!truck.specification.advertisementTitle" class="StockItemB-infoSubHeader">
                          {{ truck?.specification.axleConfiguration | lookup }} {{ truck?.specification.horsePower | hasValue }} {{ truck?.specification.category | hasValue }}
                      </div> -->
                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                      <div *ngIf="isTrailer">
                          <div class="labelRow">
                              <div class="labelName">{{ 'specification/trailerId' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck?.specification.licensePlate | hasValue }}
                              </div>
                          </div>
                          <div class="labelRow">
                              <div class="labelName">{{ 'specification/make' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck?.specification.make | hasValue }}
                              </div>
                          </div>
                          <div class="labelRow">
                              <div class="labelName">{{ 'specification/model' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck.specification.make | hasValue }} {{ truck?.specification.model | hasValue }} {{ truck?.specification.horsePower | hasValue }}
                              </div>
                          </div>
                      </div>
                      <div *ngIf="!isTrailer">
                          <div class="labelRow" *ngIf="!isOtherCategory">
                              <div class="labelName">{{ 'specification/truckId' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck.specification.licensePlate | hasValue }}
                              </div>
                          </div>
                          <div class="labelRow" *ngIf="isOtherCategory">
                            <div class="labelName">{{ 'specification/idnumber' | lowercase | translate }}:</div> <!-- for other category vehicle-->
                            <div class="labelValue">
                              {{ truck.specification.idNumber | hasValue }}
                            </div>
                          </div>
                          <div class="labelRow" *ngIf="!isOtherCategory">
                              <div class="labelName">{{ 'specification/firstRegDate' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck?.specification.dateFirstRegistration | date: 'yyyy-MM-dd' | hasValue }}
                              </div>
                          </div>
                          <!-- <div class="Table-row">
                              <div class="Table-cell">{{ 'uk/specification/trailerId' | lowercase | translate }}:</div>
                              <div class="Table-cell volvofontmedium">
                              {{ truck?.specification.licensePlate | hasValue }}
                              </div>
                          </div> -->
                          <div class="labelRow">
                              <div class="labelName">{{ 'specification/model' | lowercase | translate }}:</div>
                              <div class="labelValue" *ngIf="!truck.specification.isElectric && !isOtherCategory">
                              {{ truck.specification.make | hasValue }} {{ truck?.specification.model | hasValue }} {{ truck?.specification.horsePower | hasValue }}
                              </div>
                              <div class="labelValue" *ngIf="!truck.specification.isElectric && isOtherCategory">
                                {{ truck?.specification.model | hasValue }}
                              </div>
                              <div class="labelValue" *ngIf="truck.specification.isElectric">
                                {{ truck.specification.make | hasValue }} {{ truck?.specification.model | hasValue }} 
                              </div>
                          </div>
                          <div *ngIf="truck.specification.modelYear" class="labelRow">
                            <div class="labelName">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                            <div class="labelValue">
                              {{ truck.specification.modelYear | hasValue }}
                            </div>
                          </div>
                          <div class="labelRow" *ngIf="!isOtherCategory">
                              <div class="labelName">{{ 'specification/cabin' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck?.specification.cabType | lookup }}
                              </div>
                          </div>
                          <div class="labelRow" *ngIf="!isOtherCategory">
                              <div class="labelName">{{ 'specification/mileage' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck?.specification.mileageKm?.toLocaleString('en-GB') | hasValue: 'km' }}
                              </div>
                          </div>
                          <div class="labelRow" *ngIf="!isOtherCategory  && isServiceHistoryAvailable">
                              <div class="labelName">{{ 'specification/service' | lowercase | translate }}:</div>
                              <div class="labelValue">
                              {{ truck.specification.serviceRepairHistory | lookup }}
                              </div>
                          </div>
                          <div class="labelRow" *isUKNSSite>
                            <div class="labelName">Warranty:</div>
                            <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="labelValue">
                                Remaining factory warranty
                            </div>
                            <div *ngIf="truck?.advertisementCategory=='Selected'" class="labelValue">
                                24 months
                            </div>
                            <div *ngIf="truck?.advertisementCategory=='Approved'" class="labelValue">
                                12 months
                            </div>
                            <div *ngIf="truck?.advertisementCategory=='Economy'" class="labelValue">
                                6 months
                            </div>
                          </div>
                      </div>
                      <mat-divider class="flexTruckDetails-matDivider" *ngIf="!isOtherCategory"></mat-divider>

                      <div *ngIf="!isOtherCategory">
                          <span class="labelValueHeading">{{ 'truck/accordion/itemB/title' | lowercase | translate }}</span>
                          <div>
                              <div *ngIf="truck.specification.modelYear" class="labelRow">
                                  <div class="labelName">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                  {{ truck.specification.modelYear | hasValue }}
                                  </div>
                              </div>

                              <div *ngIf="truck.specification.horsePower" class="labelRow">
                                  <div class="labelName">{{ 'specification/engine' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                  {{ truck.specification.horsePower | hasValue: 'hp' }}
                                  </div>
                              </div>
                              <div *ngIf="
                                  truck.specification.fuelType && truck.specification.fuelType && truck.specification.engineVolumeLitres
                                  " class="labelRow">
                                  <div class="labelName">{{ 'specification/engineType' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.fuelType | lookup }} - {{ truck.specification.engineVolumeLitres | hasValue:
                                      'L' }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.emission" class="labelRow">
                                  <div class="labelName">{{ 'specification/euronorm' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.emission | hasValue }}
                                  </div>
                              </div>

                              <div *ngIf="truck.specification.batteryCapacityKWh && truck.specification.isElectric"
                                class="labelRow">
                                <div class="labelName">{{
                                  'specification/batterycapacitydisclaimer' | lowercase |
                                  translate }}:
                                </div>
                                
                                <div class="labelValue">
                                  {{ truck.specification.batteryCapacityKWh.engPhrase | hasValue: 'kWh' }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.electricMotor && truck.specification.isElectric"
                                class="labelRow">
                                <div class="labelName">{{ 'specification/electricMotor' | lowercase | translate }}:</div>
                                <div class="labelValue">
                                  {{ truck.specification.electricMotor.engPhrase | hasValue: '' }}
                                </div>
                              </div>

                              <div *ngIf="truck.specification.fuelCapacity" class="labelRow">
                                  <div class="labelName">{{ 'specification/fuelcapacity' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.fuelCapacity | hasValue: 'L' }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.numberOfTanks" class="labelRow">
                                  <div class="labelName">{{ 'specification/numberTanks' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.numberOfTanks | hasValue }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.gearBox" class="labelRow">
                                  <div class="labelName">{{ 'specification/gearBox' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.gearBox | hasValue }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.hasDiffLocks  && !isTrailer" class="labelRow">
                                  <div class="labelName">{{ 'specification/difLocks' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.hasDiffLocks | hasValue }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.brakes" class="labelRow">
                                  <div class="labelName">{{ 'specification/brakes' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.brakes | lookup }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.additionalBrakes" class="labelRow">
                                  <div class="labelName">{{ 'specification/additionalBrakes' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.additionalBrakes | lookup }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.chassisHeight" class="labelRow">
                                <div class="labelName">{{ 'specification/chassisHeight' | lowercase | translate }}:</div>
                                <div class="labelValue" data-cy="truckpage-chassisHeight">
                                  {{ truck.specification.chassisHeight | lookup }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.frontSuspension" class="labelRow">
                                  <div class="labelName">{{ 'specification/frontSuspension' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.frontSuspension | lookup }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.rearSuspension" class="labelRow">
                                  <div class="labelName">{{ 'specification/rearSuspension' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.rearSuspension | lookup }}
                                  </div>
                              </div>
                              <div *ngIf="truck.specification.cabHeater" class="labelRow">
                                <div class="labelName">{{ 'specification/cabHeater' | lowercase | translate }}:</div>
                                <div class="labelValue">
                                    {{ truck.specification.cabHeater | lookup }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.airCondition" class="labelRow">
                                  <div class="labelName">{{ 'specification/airCondition' | lowercase | translate }}:</div>
                                  <div class="labelValue">
                                      {{ truck.specification.airCondition | lookup }}
                                  </div>
                              </div>


                              <div *ngIf="truck">
                                      <!-- <mat-panel-title>
                                        <h3 class="truckDetails-title volvofontmedium CPrimary">
                                          {{ 'uk/specification/superstructure' | lowercase | translate }}
                                        </h3>
                                      </mat-panel-title> -->

                                      <!-- TODO Is this really true? superstructure.make and the rest does not exist -->
                                  <div *ngIf="
                                      truck.specification.superstructure?.make ||
                                      truck.specification.superstructure?.type ||
                                      truck.specification.superstructure?.model ||
                                      truck.specification.superstructure?.loadHandling ||
                                      truck.specification.superstructure?.numberOfCompartments ||
                                      truck.specification.superstructure?.numberOfPallets ||
                                      truck.specification.superstructure?.rearDoors ||
                                      truck.specification.superstructure?.sideDoors ||
                                      truck.specification.superstructure?.superstructure
                                      " >
                                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                      <div>
                                          <div class="labelValueHeading">
                                          {{ 'specification/superstructure' | lowercase | translate }}
                                          </div>
                                      </div>
                                      <div>
                                          <div *ngIf="truck.specification.superstructure?.type" class="labelRow">
                                              <div class="labelName">{{ 'specification/type' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure?.type }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure?.superstructure" class="labelRow">
                                              <div class="labelName">{{ 'specification/superstructure' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure?.superstructure | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.make" class="labelRow">
                                              <div class="labelName">{{ 'specification/make' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure?.make }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.model" class="labelRow">
                                              <div class="labelName">{{ 'specification/model' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.model }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.numberOfPallets" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/loadHandlingPallets' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.numberOfPallets }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.numberOfCompartments" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/loadHandlingCompartment' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.numberOfCompartments }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.loadHandling" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/loadHandling' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.loadHandling | lookup }}
                                              </div>
                                          </div>
                                          
                                          <div *ngIf="truck.specification.superstructure.rearDoors" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/loadHandlingRear' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.rearDoors | lookup }}
                                              </div>
                                          </div>

                                          <div *ngIf="truck.specification.superstructure.sideDoors" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/loadHandlingSide' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.sideDoors | lookup }}
                                              </div>
                                          </div>
                                  </div>

                                  <div *ngIf="
                                      truck.specification.superstructure.measurements &&
                                      (truck.specification.superstructure.measurements.internalLengthMm ||
                                      truck.specification.superstructure.measurements.internalWidthMm ||
                                      truck.specification.superstructure.measurements.internalHeightMm)
                                  ">
                                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                      <div>
                                          <div class="labelValueHeading">
                                          {{ 'specification/measurements' | lowercase | translate }}
                                          </div>
                                      </div>

                                      <div>
                                          <div >
                                          <div *ngIf="truck.specification.superstructure.measurements.internalLengthMm" class="labelRow">
                                              <div class="labelName">{{ 'specification/internalLength' | lowercase | translate }} (mm):</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.internalLengthMm }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.measurements.internalWidthMm" class="labelRow">
                                              <div class="labelName">{{ 'specification/internalWidth' | lowercase | translate }} (mm):</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.internalWidthMm }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.measurements.internalHeightMm" class="labelRow">
                                              <div class="labelName">{{ 'specification/internalHeight' | lowercase | translate }} (mm):</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.internalHeightMm }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.measurements.externalLengthMm" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/measurementsExternalLength' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.externalLengthMm }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.measurements.externalWidthMm" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/measurementsExternalWidth' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.externalWidthMm }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.measurements.externalHeightMm" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/measurementsExternalHeight' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.measurements.externalHeightMm }}
                                              </div>
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                  
                                  <div *ngIf="
                                      truck.specification.superstructure.tailLift?.make ||
                                      truck.specification.superstructure.tailLift?.capacity ||
                                      truck.specification.superstructure.tailLift?.modelYear||
                                      truck.specification.superstructure.tailLift?.position ||
                                      truck.specification.superstructure.tailLift?.dateLegalInspection
                                  ">
                                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                      <div class="labelValueHeading">
                                          {{ 'specification/tailLift' | lowercase | translate }}
                                      </div>

                                      <div >
                                          <div *ngIf="truck.specification.superstructure.tailLift?.make" class="labelRow">
                                              <div class="labelName">{{ 'specification/make' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.tailLift?.make | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.tailLift.modelYear" class="labelRow">
                                              <div class="labelName">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.tailLift.modelYear }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.tailLift.position" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/position' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.tailLift.position | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.tailLift.capacity" class="labelRow">
                                              <div class="labelName">{{ 'specification/capacity' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                              {{ truck.specification.superstructure.tailLift.capacity | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="(truck.specification.superstructure.tailLift.dateLegalInspection | date) !== 'Jan 1, 1'" class="labelRow">
                                              <div class="labelName">
                                                  {{ 'superstructure/craneDate' | lowercase | translate }}
                                              </div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.tailLift.dateLegalInspection | date }}
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div *ngIf="
                                      truck.specification.superstructure.crane?.dateLegalInspection ||
                                      truck.specification.superstructure.crane?.extensions ||
                                      truck.specification.superstructure.crane?.make ||
                                      truck.specification.superstructure.crane?.other||
                                      truck.specification.superstructure.crane?.model ||
                                      truck.specification.superstructure.crane?.modelYear ||
                                      truck.specification.superstructure.crane?.capacity
                                      ">
                                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                      <div class="labelValueHeading">
                                      {{ 'superstructure/crane' | lowercase | translate }}
                                      </div>
                                      <div>
                                          <div *ngIf="truck.specification.superstructure.crane?.make" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/make' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane?.make.engPhrase }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane?.model" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/model' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane?.model }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane?.modelYear" class="labelRow">
                                              <div class="labelName">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane?.modelYear }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane?.capacity" class="labelRow">
                                              <div class="labelName">{{ 'specification/capacity' | lowercase | translate }}:</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane?.capacity | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane.extensions" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/craneExtensions' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane.extensions | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane.other" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/craneOther' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.crane.other | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.crane.dateLegalInspection" class="labelRow">
                                          <div class="labelName">
                                              {{ 'superstructure/craneDate' | lowercase | translate }}
                                          </div>
                                          <div class="labelValue">
                                              {{ truck.specification.superstructure.crane.dateLegalInspection | date }}
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                                      


                                  <div *ngIf="
                                      truck.specification.superstructure.temperatureControl &&
                                      (truck.specification.superstructure.temperatureControl?.aggregateType ||
                                          truck.specification.superstructure.temperatureControl?.dateLegalInspection ||
                                          truck.specification.superstructure.temperatureControl?.make ||
                                          truck.specification.superstructure.temperatureControl?.modelYear ||
                                          truck.specification.superstructure.temperatureControl?.position)||
                                          truck.specification.superstructure.temperatureControl?.description
                                      ">
                                      <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                      <div class="labelValueHeading">
                                      {{ 'superstructure/TempControl' | lowercase | translate }}
                                      </div>

                                      <div >
                                          
                                          <div *ngIf="truck.specification.superstructure.temperatureControl?.make" class="labelRow">
                                          <div class="labelName">{{ 'superstructure/TempControlMake' | lowercase | translate }}</div>
                                          <div class="labelValue">
                                              {{ truck.specification.superstructure.temperatureControl?.make | lookup }}
                                          </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.temperatureControl.modelYear"
                                          class="labelRow">
                                          <div class="labelName">{{ 'superstructure/TempControlYear' | lowercase | translate }}</div>
                                          <div class="labelValue">
                                              {{ truck.specification.superstructure.temperatureControl.modelYear }}
                                          </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.temperatureControl.position" class="labelRow">
                                          <div class="labelName">{{ 'superstructure/TempControlMounting' | lowercase | translate }}</div>
                                          <div class="labelValue">
                                              {{ truck.specification.superstructure.temperatureControl.position | lookup }}
                                          </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.temperatureControl.aggregateType" class="labelRow">
                                              <div class="labelName">
                                                  {{ 'superstructure/temperaturecontrol/aggregatetype' | lowercase | translate }}
                                              </div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.temperatureControl.aggregateType | lookup
                                                  }}
                                              </div>
                                          </div>
                                          <div *ngIf="truck.specification.superstructure.temperatureControl.description" class="labelRow">
                                              <div class="labelName">{{ 'superstructure/description' | lowercase | translate }}</div>
                                              <div class="labelValue">
                                                  {{ truck.specification.superstructure.temperatureControl.description | lookup }}
                                              </div>
                                          </div>
                                          <div *ngIf="(truck.specification.superstructure.temperatureControl.dateLegalInspection| date) !== 'Jan 1, 1'"
                                          class="labelRow">
                                          <div class="labelName">{{ 'superstructure/TempControlDate' | lowercase | translate }}</div>
                                          <div class="labelValue">
                                              {{ truck.specification.superstructure.temperatureControl.dateLegalInspection | date
                                              }}
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                  <!-- </div> -->
                              </div>
                              <div>
                                  <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                                  <div class="labelValueHeading">
                                      {{ 'truck/accordion/itemC/title' | lowercase | translate }}
                                  </div>
                                  <div *ngIf="truck.specification.axleConfiguration" class="labelRow">
                                      <div class="labelName">{{ 'specification/configuration' | lowercase | translate }}:</div>
                                      <div class="labelValue">
                                          {{ truck?.specification.axleConfiguration | lookup }}
                                      </div>
                                  </div>
                                  <div *ngIf="truck.specification.wheelBaseMm" class="labelRow">
                                      <div class="labelName">{{ 'specification/wheelbase' | lowercase | translate }} (mm):</div>
                                      <div class="labelValue">
                                          {{ truck.specification.wheelBaseMm | hasValue }}
                                      </div>
                                  </div>
                                  <div *ngIf="truck.specification.rearAxleType" class="labelRow">
                                      <div class="labelName">{{ 'specification/rearAxleType' | lowercase | translate }}:</div>
                                      <div class="labelValue">
                                          {{ truck.specification.rearAxleType | lookup }}
                                      </div>
                                  </div>
                                  <div *ngIf="truck.specification.rearAxleRatio" class="labelRow">
                                      <div class="labelName">{{ 'specification/rearAxleRatio' | lowercase | translate }}:</div>
                                      <div class="labelValue">
                                          {{ truck.specification.rearAxleRatio | hasValue }}
                                      </div>
                                  </div>
                                  <div *ngFor="let item of truck.specification.tyreTread; index as i">
                                      <div *ngIf="truck.specification.axleConfigurationLayout" class="labelRow">
                                          <div *ngIf="item.TyreWidthMm > 0" class="labelName">Axle {{i + 1}}:</div>
                                          <div *ngIf="item.TyreWidthMm > 0" class="labelValue">
                                              {{item.TyreWidthMm }}/{{item.TyreProfile}}R{{item.TyreDimension }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="(truck.specification.frontAxleLoadKg || truck.specification.vehicleWeightWithoutLoadKg) && !isOtherCategory">
                        <mat-divider class="flexTruckDetails-matDivider"></mat-divider>
                        <div class="labelValueHeading">
                            {{ 'truck/accordion/itemg/title' | lowercase | translate }}
                        </div>
                        <div *ngIf="truck.specification.vehicleWeightWithoutLoadKg" class="labelRow">
                            <div class="labelName">{{ 'truck/vehicleweight/vehicleWeightWithoutLoadKg' | lowercase | translate }}:</div>
                            <div class="labelValue">
                                {{ truck.specification.vehicleWeightWithoutLoadKg }}
                            </div>
                        </div>
                        <div *ngIf="truck.specification.frontAxleLoadKg" class="labelRow">
                            <div class="labelName">{{ 'truck/vehicleweight/frontAxleLoadKg' | lowercase | translate }}:</div>
                            <div class="labelValue">
                                {{ truck.specification.frontAxleLoadKg }}
                            </div>
                        </div>
                      </div>
                      <mat-divider *ngIf="truck.vehicleAdditionalEquipment" class="flexTruckDetails-matDivider"></mat-divider>
                      <div *ngIf="truck.vehicleAdditionalEquipment" class="equipmentContent">
                          <span class="labelValue">{{ 'truck/accordion/itemD/title' | lowercase | translate }}</span>
                          <div style="display: block;">
                              <div *ngFor="let item of truck.vehicleAdditionalEquipment;let last = last" class="equipmentList">
                                 <div *ngIf="!last">
                                      {{ item.engPhrase }},&nbsp;
                                  </div>
                                 <div *ngIf="last">
                                      {{ item.engPhrase }}
                                  </div>
                              </div>
                          </div>
                      </div>
                      <mat-divider *ngIf="truck.specification.comment" class="flexTruckDetails-matDivider"></mat-divider>
                      <div *ngIf="truck.specification.comment" class="additionalPadding">
                          <span  class="labelValue">{{ 'truck/accordion/itemE/title' | lowercase | translate }}</span>
                          <div>
                              <div [innerHTML]="truck.specification.comment.engPhrase | safe: 'html'"  class="equipment" ></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
    <div *ngIf="truck">
    <div class="truckImg">
        <div *ngFor="let item of truck.pictures; index as i" style="page-break-inside: avoid;break-inside: avoid;">
            <img [src]="item.filenameSmall | volvoCdn" alt="" class="imageDiv" />
        </div>
        </div>
    </div>
