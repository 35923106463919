import { Component,OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalUtils } from '../../core/utils/global-utils/global-utils';
import { ScreenUtils } from '../../core/utils/screen-utils/screen-utils';
import { LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CounterService } from '~/core/services/counter/counter-service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvo-truck-model-page',
  templateUrl: './truck-model-page.component.html',
  styleUrls: ['./truck-model-page.component.scss'],
})
export class TruckModelPageComponent implements OnInit {
  HtmlRoutePages: HtmlRoutePages;
  link: string = "";
  isTablet = false;
    isLoading: boolean[] = Array(6).fill(true);
    truckmodelObject =[]
  truckmodelTempObject = [
    {
      url:'assets/pages/truckModel/volvoFH16.webp',
      title:'pages/truckModel/volvoFH16',
      text:'pages/truckModel/volvoFH16/text',
      path: HtmlRoutePages.volvoFH16,
      name:'Volvo FH16',
      altText: 'pages/truckmodel/images/fh16/altimage1'
    },
    {
      url:'assets/pages/truckModel/volvoFH.webp',
      title:'pages/truckModel/volvoFH',
      text:'pages/truckModel/volvoFH/text',
      path: HtmlRoutePages.volvoFH,
      name:'Volvo FH',
      altText: 'pages/truckmodel/images/fh/altimage1'
    },
    {
      url:'assets/pages/truckModel/volvoFM.webp',
      title:'pages/truckModel/volvoFM',
      text:'pages/truckModel/volvoFM/text',
      path:HtmlRoutePages.volvoFM,
      name:'Volvo FM',
      altText: 'pages/truckmodel/images/fm/altimage1'
    },
    {
      url:'assets/pages/truckModel/volvoFMX.webp',
      title:'pages/truckModel/volvoFMX',
      text:'pages/truckModel/volvoFMX/text',
      path:HtmlRoutePages.volvoFMX,
      name:'Volvo FMX',
      altText: 'pages/truckmodel/images/fmx/altimage1'
    },
    {
      url:'assets/pages/truckModel/volvoFE.webp',
      title:'pages/truckModel/volvoFE',
      text:'pages/truckModel/volvoFE/text',
      path:'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fe.html',
      name:'Volvo FE',
      altText: 'pages/truckmodel/images/fe/altimage1'
    },
    {
      url:'assets/pages/truckModel/volvoFL.webp',
      title:'pages/truckModel/volvoFL',
      text:'pages/truckModel/volvoFL/text',
      path:'https://www.volvotrucks.com/en-en/trucks/trucks/volvo-fl.html',
      name:'Volvo FL',
      altText: 'pages/truckmodel/images/fl/altimage1'
    }
  ];
  constructor(private route: ActivatedRoute, private router: Router, private globalUtils: GlobalUtils, private localizeRouterService: LocalizeRouterService, private titleService: Title,
    private metaTagService: Meta,
      private translateService: TranslateService, private counterService: CounterService, public assetService: AssetService) {
      //this.isLoading = Array(this.truckmodelTempObject.length).fill(true);
      this.truckmodelObject = this.truckmodelTempObject
}
  ngOnInit()
  {
    
    if (ScreenUtils.isTablet()) {
      this.isTablet=true;
    }
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('truckModel/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('truckModel/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/altImage'.toLowerCase())
      });
    }
    onImageLoad(index: number): void {
        this.isLoading[index] = false
    }
     logTruckModelButtonClick(model:string) {
        this.counterService.postTruckModelClick(model);
      }
}
