<section class="economy">
    <div class="tabMobSidePadding">
        <div class="economy-economyDiv">
            <div class="tabMobHeroMargin">
                <img [src]="assetService.getAssetUrl('assets/pages/vehicleOffers/UTEconomy.webp')" class="economy-economyImg" alt=""/>
            </div>
            <div class="economy-title">
                <span>{{ "uk/components/footer/volvoEconomy" | lowercase | translate }}</span>
            </div>
            <div class="economy-text">
                <span>{{ "uk/pages/vehicleOffer/economy/Text1" | lowercase | translate }}</span>
            </div>
            <div class="economy-text">
                <span>{{ "uk/pages/vehicleOffer/economy/Text2" | lowercase | translate }}</span>
            </div>
            <div class="economy-text">
                <span class="economy-text-medium">{{ "uk/pages/vehicleOffer/economy/Text3" | lowercase | translate }}</span>{{ "uk/pages/vehicleOffer/economy/Text4" | lowercase | translate }}
            </div>
            <div class="economy-text">
                <span>{{ "uk/pages/vehicleOffer/economy/Text5" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="economy-list">
            <ul class="economy-list-title" *ngFor="let list of economyList">
                {{
          list.type | lowercase | translate
                }}
                <li *ngFor="let label of list.label">
                    {{ label.name | lowercase | translate }}
                </li>
            </ul>
        </div>
        <div class="volvoFactSheet paddingBottom">
            <button
              class="navigation-buttonFact"
              (click)="openFactSheet()"
              [attr.aria-label]=" 'uk/pages/services/economyfact' | lowercase | translate "
            >
              <span class="navigation-button-text">
                {{ "uk/pages/services/economyfact" | lowercase | translate }}
              </span>
              <img
                [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')"
                alt="{{ 'pages/truckmodel/readmore' | lowercase | translate }}"
              />
            </button>
        </div>
        
        </div>
</section>
  <volvo-footer></volvo-footer>
