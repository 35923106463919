<div *ngIf="globalUtils.isBrowser()" class="Carousel CPrimary">
  <drag-scroll #slider [snap-offset]="65" [ngClass]="_users?.length >= 2 ? 'drag-scroll' : 'drag-scroll-single'"
    (reachesLeftBound)="leftBoundStat($event)" (reachesRightBound)="rightBoundStat($event)">
    <div drag-scroll-item *ngFor="let user of _users; index as i;">
      <ng-container>
        <div class="Carousel-item">
          <div class="Carousel-itemLeft">
            <div class="Carousel-itemName volvofontmedium">{{ user.name }}</div>
            <div class="Carousel-itemInfo CPrimary">{{ user.info }}</div>
            <div class="Carousel-itemLocation">{{ user.locationName }} <br /></div>
            <div *ngIf="user.phone !== undefined && user.phone !== null && user.phone !== ''"
              style="justify-content: space-between;" (click)="togglePhone(i)">
              <a href="tel:{{ user.phone }}">
                <div class="phoneBtn phoneBtn-mobileBtn lighthoverEffects">
                  <i class="fas fa-phone-alt Colordark phonePadding"></i>
                  <div id="phId_{{i}}" *ngIf="!isPhone" class="Carousel-itemPhone Colordark Carousel-hideNumber">{{
                    user.phone }}</div>
                  <div *ngIf="isPhone" class="Carousel-itemPhone Colordark">{{'form/callus' | lowercase | translate}}
                  </div>
                </div>
              </a>
            </div>
            <button class=" phoneBtn phoneBtn-email hoverEffects" (click)="onContactsClick(i)">
              <i class="fal fa-envelope ColorWhite phonePadding"></i>
              <div class="Carousel-itemPhone ColorWhite">{{'form/sendMessage' | lowercase | translate}}</div>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </drag-scroll>

  <div *ngIf="_users?.length >= 2">
    <button (click)="slider.moveLeft();" [disabled]="leftNavDisabled" class="Carousel-moveLeft sliderButton" aria-label="Move left"><i
        class="fal fa-chevron-left"></i><span class="sr-only">Move left</span></button>
    <button (click)="slider.moveRight();" [disabled]="rightNavDisabled" class="Carousel-moveRight sliderButton" aria-label="Move right"><i
        class="fal fa-chevron-right"></i><span class="sr-only">Move right</span></button>
  </div>
</div>