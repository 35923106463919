<div class="DealerShip">
  <div class="DealerShip-left" style="padding-top: 0px">
    <!-- <div class="DealerShip-title">{{'contact/dealership/title' | lowercase | translate}}</div> -->

    <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
      <mat-select class="volvofontmedium CPrimary volvofontweight" [(value)]="selectedFilterCountry"
        (openedChange)="onFilterByCountry($event)" [placeholder]="'globalMiss.filterbycountry' | lowercase | translate"
        data-cy="aboutus-dealershipdd">
        <mat-option class="Option CPrimary" (click)="filterByCountry('All Markets')" value="All Markets">
          {{ 'search/filter/country/allmarkets' | lowercase | translate }}
        </mat-option>
        <mat-option class="Option volvofont CPrimary"
          *ngFor="let item of dealershipsService.dealershipsCountries$ | async" [value]="item"
          data-cy="aboutus-dealershipitems">{{ 'search/filter/country/' + item | lowercase | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider class="DealerShip-left-divider"></mat-divider>

    <div class="DealerShip-scroll">
      <mat-accordion *ngIf="dealershipLocations; else loading" class="VolvoAccordionDealership">
        <ng-container *ngFor="let item of dealershipLocations">
          <mat-expansion-panel id="dealership-{{item.stockLocation.stockLocationIdentifier}}" [expanded]="item.isExpanded" tabindex="0"
          (keydown.enter)="togglePanel(item)" (keydown.space)="togglePanel(item)">
            <mat-expansion-panel-header (click)="goToAddress(item)" collapsedHeight="*" expandedHeight="*"
              data-cy="aboutus-dealershipcount" >
              <mat-panel-title>
                <div class="DealerShip-itemTitle volvofontmedium" data-cy="aboutus-dealername">
                  {{ item.stockLocation.stockLocationName }}
                </div>

                <div class="DealerShip-itemDescription volvofont" data-cy="aboutus-dealershipaddress">
                  {{ item.formatted_address }}
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <div class="DealerShip-body">
                <a *ngIf="userLocation?.lat && userLocation?.lng && item?.geometry?.location?.LatLng" target="_blank"
                  href="https://www.google.com/maps/dir/{{ userLocation.lat }},{{ userLocation.lng }}/{{
                    item.geometry.location.lat()
                  }},{{ item.geometry.location.lng() }}"
                  aria-label="Get directions to {{ item.stockLocation.stockLocationName }}">
                  <i class="far fa-map-marker-alt"></i>&nbsp;{{ 'global/getDirections' | lowercase | translate }}
                </a>

                <div *ngIf="place.hasOpenHours" class="DealerShip-information volvofontmedium CPrimary">
                  {{ 'global/openingHours' | lowercase | translate }}*
                  <br />
                  <span *ngFor="let item of place.weekdayText">{{ item }}
                    <br />
                  </span>
                </div>
                <br />
                <div class="DealerShip-disclaimer volvofont CPrimary">
                  * Sales personnel is not available during all opening hours of the dealership
                </div>
                <div class="buttonCss">
                  <button 
                  tabindex="0"
                  (click)="onContactDealershipClick(item)"
                  (keydown.enter)="onContactDealershipClick(item)"
                  (keydown.space)="onContactDealershipClick(item)"
                    data-cy="contact-dealershipbtn"
                    aria-label="Contact dealership {{ item.stockLocation.stockLocationName }}">
                    <!-- <a></a> -->
                    <span>{{ 'pages/contact' | lowercase | translate }}</span>
                  </button>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>

      <ng-template #loading>
        <volvo-loading></volvo-loading>
      </ng-template>
    </div>
  </div>

  <div class="DealerShip-right">
    <div #mapContainer id="map"></div>
  </div>
</div>