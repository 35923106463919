import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

// import { BucketsService } from '~/core/store/buckets/buckets.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { Truck } from '~/core/store/trucks/trucks.model';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
import { DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';

interface Place {
  hasOpenHours: boolean;
  isOpen: boolean;
  weekdayText: string[];
}

@Component({
  selector: 'volvo-truck-page-dealership',
  templateUrl: './truck-page-dealership.component.html',
  styleUrls: ['./truck-page-dealership.component.scss'],
})
export class TruckPageDealershipComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() truck: Truck;
  @ViewChild('mapContainer') gmap: ElementRef;
  map: any;
  isBrowser: boolean;
  place: Place = {
    hasOpenHours: false,
    isOpen: false,
    weekdayText: [],
  };
  resp: any;

  constructor(
    public dialog: MatDialog,
    private globalUtils: GlobalUtils,
    private ref: ChangeDetectorRef,
    private dealershipsService: DealershipsService
  ) {
    this.isBrowser = this.globalUtils.isBrowser();
    this.dealershipsService.getDealerships();
  }

  @Input() selectedCentre: string;

  ngOnChanges(changes: SimpleChanges): void {
    for (const prop in changes) {
      if (changes.hasOwnProperty(prop)) {
        if (prop === 'truck') {
          this.getDealership();
        }
      }
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.mapInitializer();
      this.ref.detectChanges();
    }
  }

  mapInitializer() {
    const goteborg = { lat: 57.7089, lng: 11.9746 };
    this.map = new google.maps.Map(this.gmap.nativeElement, {
      center: goteborg,
      zoom: 8,
      gestureHandling: 'cooperative',
    });
    this.getDealership();
  }

  private getDealership(): void {
    this.dealershipsService.dealerships$.subscribe((result: DealerPlaceResult[]) => {
      if (!result) return;
      const dealership = result.filter(
        (r) =>
          r.stockLocation.address.includes(this.truck.stockLocation.address) &&
          r.stockLocation.city.includes(this.truck.stockLocation.city)
      )[0];
      if (!dealership || !this.map) return;
      this.map.setCenter(dealership.geometry.location);
      new google.maps.Marker({
        map: this.map,
        position: dealership.geometry.location,
        icon: 'assets/svg/marker.svg',
      });

      if (dealership.opening_hours) {
        this.place = {
          hasOpenHours: true,
          isOpen: true,
          weekdayText: dealership.opening_hours.weekday_text,
        };
      }
    });
  }
}
