import { Component,OnInit, ChangeDetectorRef ,ViewChild, SimpleChanges } from '@angular/core';
import { TruckModelService } from '../../../core/services/truckmodel/truckmodel-service';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
  selector: 'volvoFMX-page',
  templateUrl: '../model-page.component.html',
  styleUrls: ['../model.scss']
})
export class volvoFMXPageComponent implements OnInit{
  leftNavDisabled = false;
  rightNavDisabled = false;
  leftCabNavDisabled = false;
  rightCabNavDisabled = false;
  modelName:string='pages/truckModel/volvoFMX';
  imagePath:string='assets/pages/truckModel/volvoFMX.webp';
  imageAltText:string='pages/truckmodel/images/fmx/altimage1';
  modelTitle:string="pages/truckmodel/volvofmx/title";
  modelText:string="pages/truckModel/volvoFMX/classic/text"
    model: string = 'FMX';
    isLoading: boolean = true;
  modelObj = [
    {
      url: 'assets/pages/truckModel/volvoFMX/image1.webp',
      title: 'pages/truckModel/classic/highlights/title1',
      text: 'pages/truckModel/classic/highlights/title1/text2',
      altImg: 'pages/truckmodel/images/fmx/altimage2'
    },
    {
      url: 'assets/pages/truckModel/volvoFMX/image3.webp',
      title: 'pages/truckModel/classic/highlights/title10',
      text: 'pages/truckModel/classic/highlights/title4/text1',
      altImg: 'pages/truckmodel/images/fmx/altimage3'
    },
    {
      url: 'assets/pages/truckModel/volvoFM/image2.webp',
      title: 'pages/truckModel/classic/highlights/title4',
      text: 'pages/truckModel/classic/highlights/title4/text3',
      altImg: 'pages/truckmodel/images/fmx/altimage4'
    },
    {
      url: 'assets/pages/truckModel/volvoFH16/image2.webp',
      title: 'pages/truckModel/classic/highlights/title2',
      text: 'pages/truckModel/classic/highlights/title2/text2',
      altImg: 'pages/truckmodel/images/fmx/altimage5'
    },
    {
      url: 'assets/pages/truckModel/volvoFMX/image6.webp',
      title: 'pages/truckModel/classic/highlights/title7',
      text: 'pages/truckModel/classic/highlights/title7/text1',
      altImg: 'pages/truckmodel/images/fmx/altimage6'
    },
    {
      url: 'assets/pages/truckModel/volvoFMX/image7.webp',
      title: 'pages/truckModel/classic/highlights/title11',
      text: 'pages/truckModel/classic/highlights/title11/text1',
      altImg: 'pages/truckmodel/images/fmx/altimage7'
    }
  ];
  designObj = [
    {
      url: 'assets/pages/truckModel/volvoFMX/image4.webp',
      title: 'pages/truckModel/exterior',
      text: 'pages/truckModel/exterior/FMX',
      altImg: 'pages/truckmodel/images/fmx/altimage8'
    },
    {
      url: 'assets/pages/truckModel/volvoFMX/image5.webp',
      title: 'pages/truckModel/interior',
      text: 'pages/truckModel/interior/FMX',
      altImg: 'pages/truckmodel/images/fmx/altimage9'
    },
  ]
  cabObj = [
    {
      url: 'assets/pages/truckModel/volvoFMX/engine1.webp',
      title: 'pages/truckModel/cab/option8',
      text: 'pages/truckModel/cab/option8/text2',
      altImg: 'pages/truckmodel/images/fmx/altimage10'
    },
    
    {
      url: 'assets/pages/truckModel/volvoFMX/engine3.webp',
      title: 'pages/truckModel/cab/option3',
      text: 'pages/truckModel/cab/option3/text3',
      altImg: 'pages/truckmodel/images/fmx/altimage11'
    },
    {
      url: 'assets/pages/truckModel/volvoFMX/engine4.webp',
      title: 'pages/truckModel/cab/option7',
      text: 'pages/truckModel/cab/option7/text1',
      altImg: 'pages/truckmodel/images/fmx/altimage12'
    }
  ]
  engineObj = [
    {
      name: 'pages/truckModel/engines',
      categories: [
        {
          title: 'pages/truckModel/engine4',
          colums: [
            { column1: 'pages/truckModel/engine4/power1', column2: '330 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine4/power2', column2: '1600 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine5',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '380 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine2/power2', column2: '3150 ', column3:'pages/truckModel/engines/nm' }
          ]
        },
        {
          title: 'pages/truckModel/engine16',
          colums: [
            { column1: 'pages/truckModel/engine3/power1', column2: '750 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine3/power2', column2: '3550 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine7',
          colums: [
            { column1: 'pages/truckModel/engine5/power1', column2: '460 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power3', column2: '2200 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine8',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '420 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine7/power2', column2: '2100 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine9',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '460 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine9/power1', column2: '2300 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine10',
          colums: [
            { column1: 'pages/truckModel/engine7/power1', column2: '500 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine10/power1', column2: '2500 ', column3:'pages/truckModel/engines/nm'}
          ]
        },
        {
          title: 'pages/truckModel/engine10',
          colums: [
            { column1: 'pages/truckModel/engine15/power1', column2: '540 ', column3:'pages/truckModel/engines/hp' },
            { column1: 'pages/truckModel/engine15/power2', column2: '2600 ', column3:'pages/truckModel/engines/nm'}
          ]
        }
      ],
      text:'pages/truckModel/engine/text/fmx',
      type:'pages/truckModel/volvoFMX'
    }
  ]

  @ViewChild('slider', { static: false }) slider: any;
  @ViewChild('sliderCab', { static: false }) sliderCab: any;
  constructor(
    public TruckModelService: TruckModelService,
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService,
    private cdr: ChangeDetectorRef
  ) {
    
  }
  ngOnInit()
  {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('truckModel/volvofmx/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofmx/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofmx/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('truckModel/volvofmx/metatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('truckModel/volvofmx/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('truckModel/volvofmx/altImage'.toLowerCase())
      });

      //this.breadCrumbService.updateBreadcrumb('Truck Models' , 'Volvo FMX', HtmlRoutePages.truckModels);
    }
    onImageLoad(): void {
      this.isLoading = false
      this.triggerNavStatusCheck();
  }

  ngAfterViewInit() {
    // Force boundary detection after the view initializes
    this.cdr.detectChanges(); // Trigger change detection

    if (this.slider) {
      this.slider.checkNavStatus();
    }
    if (this.sliderCab) {
      this.sliderCab.checkNavStatus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modelObj']) {
      this.onSliderBoundaryChanges();
    }
    if (changes['cabObj']) {
      this.onSliderCabBoundaryChanges();
    }
    // Always trigger boundary check after changes
    this.triggerNavStatusCheck();
  }

  onSliderBoundaryChanges() {
    setTimeout(() => {
        if (this.slider) {
            this.slider.checkNavStatus();
        }
    }, 100);
  }

  onSliderCabBoundaryChanges() {
      setTimeout(() => {
          if (this.sliderCab) {
              this.sliderCab.checkNavStatus();
          }
      }, 100);
  }

  triggerNavStatusCheck() {
    if (this.slider) {
      this.slider.checkNavStatus();
    }
    if (this.sliderCab) {
      this.sliderCab.checkNavStatus();
    }
  }
}
